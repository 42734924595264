import {i18next} from "../../../i18next";
import {Skill} from "./Skill";

i18next
	.addResourceBundle("en", "translation", {
		Documentation: {
			Skillsheet: {
				Skills: {
					CDevelopment: `C Development`
				}
			}
		}
	}, true)
	.addResourceBundle("nl", "translation", {
		Documentation: {
			Skillsheet: {
				Skills: {
					CDevelopment: `C Ontwikkeling`
				}
			}
		}
	}, true);

export const CDevelopment = new Skill(null, <>C</>);

function update() {
	CDevelopment.name = i18next.t("Documentation.Skillsheet.Skills.CDevelopment");
}

i18next.on("languageChanged", language => {
	update();
});

update();
