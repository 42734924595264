import {Component} from "./Component";

const Wrapper = Component.Section`
	width: 100%;
`;

/**
 * A hidden section of page on top.
 */
export class HiddenTopSection extends Component {
	/**
	 * Creates a new HiddenTopSection.
	 * @param properties The properties.
	 */
	constructor(properties) {
		super(properties);

		this.state = {
			wrapperReference: Component.CreateReference()
		};
	}

	_afterMount() {
		this.state.wrapperReference.current.nextSibling.scrollIntoView();
	}

	_onRender() {
		return (
			<Wrapper ref={this.state.wrapperReference}>
				{this.children}
			</Wrapper>
		);
	}
}
