import {Planet} from "@qub1/spaceshow";
import {Component} from "../../Layout/Component";
import {Content} from "../../Layout/Content";
import {Gallery} from "../../Multimedia/Gallery";
import {Article} from "./Article";

const planets = [];
for (let planet = 0; planet < 10; ++planet) {
	planets.push(Component.Clone(Planet.CreateRandom(), {
		key: planet,
		diameter: "20em"
	}));
}

/**
 * Planetarium easter egg.
 */
export class Planetarium extends Component {
	_onRender() {
		return (
			<Article>
				<Content>
					<Gallery>
						{planets}
					</Gallery>
				</Content>
			</Article>
		);
	}
}
