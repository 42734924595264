import "@fontsource/lobster";
import "@fontsource/oswald";
import {faFacebook, faLinkedin, faTwitch, faTwitter, faYoutube} from "@fortawesome/free-brands-svg-icons";
import {faEnvelope, faLink} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Component} from "../../Layout/Component";

const Wrapper = Component.Div`
	align-items: center;
	color: #eee;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	font-size: 0.8em;
	gap: 2em;
	justify-content: center;
	text-shadow: 0.15em 0.15em #292929;
	white-space: nowrap;
`;

const Column = Component.Div`
	align-items: center;
	display: flex;
	flex-direction: column;
	justify-content: center;
`;

const Name = Component.Div`
	color: #5ba6d4;
	font-family: Lobster, sans-serif;
	font-size: 1.75em;
`;

const Nickname = Component.Span`
	color: #bf4e4e;
`;

const Portrait = Component.Img`
	border-radius: 100%;
	box-shadow: 0 0 0 0.25em #0b1538, 0 0 0 0.5em #062e6c, 0 0 0 0.75em #08255a, 0 0 0 1em #091e4b, 0 0 0.25em 1em #09102b;
	height: 10em;
	margin: 1.1em 0;
	vertical-align: middle;
`;

const SocialList = Component.Ul`
	font-family: Oswald, sans-serif;
	font-size: 0.9em;
	list-style-type: none;
	margin-top: 0.5em;
	text-align: left;
	text-shadow: 0.1em 0.1em #292929;
`;

const SocialItem = Component.Li`
`;

const SocialLink = Component.A`
	text-decoration: none;
`;

const SocialIcon = Component.Wrapper(FontAwesomeIcon)`
	color: #5ba6d4;
	min-width: 1em;
	text-align: center;
`;

const SocialName = Component.Span`
	color: white;
	margin: 0 0.75em;
`;

const SocialNickname = Component.Span`
	color: #aaa;
	font-size: 0.8em;
`;

/**
 * Represents contact information.
 */
export class ContactCard extends Component {
	_onRender() {
		return (
			<Wrapper className={this.properties.className}>
				<Column>
					<Portrait src={this.properties.contact.image}/><br/>
				</Column>
				<Column>
					<Name><Nickname>Qu</Nickname>incy <Nickname>B</Nickname>akker</Name>
					<SocialList>
						<SocialItem>
							<SocialLink href="https://qub1.com" target="_blank">
								<SocialIcon icon={faLink}/>
								<SocialName>Website</SocialName>
								<SocialNickname>www.qub1.com</SocialNickname>
							</SocialLink>
						</SocialItem>
						<SocialItem>
							<SocialLink href="mailto:qub1995@gmail.com" target="_blank">
								<SocialIcon icon={faEnvelope}/>
								<SocialName>E-mail</SocialName>
								<SocialNickname>{this.properties.contact.emailAddress.fullAddress}</SocialNickname>
							</SocialLink>
						</SocialItem>
						<SocialItem>
							<SocialLink href="https://nl.linkedin.com/in/qub1995" target="_blank">
								<SocialIcon icon={faLinkedin}/>
								<SocialName>LinkedIn</SocialName>
								<SocialNickname>qub1995</SocialNickname>
							</SocialLink>
						</SocialItem>
						<SocialItem>
							<SocialLink href="https://www.facebook.com/Qub1995" target="_blank">
								<SocialIcon icon={faFacebook}/>
								<SocialName>Facebook</SocialName>
								<SocialNickname>Qub1995</SocialNickname>
							</SocialLink>
						</SocialItem>
						<SocialItem>
							<SocialLink href="https://www.twitter.com/Qub1995" target="_blank">
								<SocialIcon icon={faTwitter}/>
								<SocialName>Twitter</SocialName>
								<SocialNickname>@Qub1995</SocialNickname>
							</SocialLink>
						</SocialItem>
						<SocialItem>
							<SocialLink href="https://www.twitch.tv/qub1995" target="_blank">
								<SocialIcon icon={faTwitch}/>
								<SocialName>Twitch</SocialName>
								<SocialNickname>qub1995</SocialNickname>
							</SocialLink>
						</SocialItem>
						<SocialItem>
							<SocialLink href="https://www.youtube.com/user/Qub1" target="_blank">
								<SocialIcon icon={faYoutube}/>
								<SocialName>YouTube</SocialName>
								<SocialNickname>Qub1</SocialNickname>
							</SocialLink>
						</SocialItem>
						{/*<SocialItem>*/}
						{/*	<SocialLink href="https://www.reddit.com/user/Qub1" target="_blank">*/}
						{/*		<SocialIcon icon={faReddit}/>*/}
						{/*		<SocialName>Reddit</SocialName>*/}
						{/*		<SocialNickname>Qub1</SocialNickname>*/}
						{/*	</SocialLink>*/}
						{/*</SocialItem>*/}
					</SocialList>
				</Column>
			</Wrapper>
		);
	}
}

Component.DefaultProperties(ContactCard, {
	/**
	 * The Contact to display.
	 * @type {Contact}
	 */
	contact: null
});
