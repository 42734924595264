import {Component} from "../../Layout/Component";
import {Content} from "../../Layout/Content";
import {ParseTranslate} from "../../Layout/ParseTranslate";
import {Section} from "../../Layout/Section";
import {Skillsheet} from "../Skillsheet/Skillsheet";
import {Article} from "./Article";

/**
 * My personal skills.
 */
export const Skills = Component.Translate(class Skills extends Component {
	_afterMount() {
		document.title = this.translate("Documents.Articles.Skills.Title");
	}

	_onRender() {
		return (
			<Article>
				<Content>
					<Section title={this.translate("Documents.Articles.Skills.Title")}>
						<ParseTranslate translationKey="Documents.Articles.Skills.Contents">
							<p/>
						</ParseTranslate>
					</Section>
					<Skillsheet skillLevels={this.properties.skillLevels} projects={this.properties.projects}/>
				</Content>
			</Article>
		);
	}
}, [
	{
		language: "en",
		translations: {
			Documents: {
				Articles: {
					Skills: {
						Title: `Skills`,
						Contents: `<0>
							The sheet below shows an overview of my skills.
							You can click the expansion button to see the specific projects I have contributed to that required use of that skill.
						</0>`
					}
				}
			}
		}
	}, {
		language: "nl",
		translations: {
			Documents: {
				Articles: {
					Skills: {
						Title: `Vaardigheden`,
						Contents: `<0>
							Het overzicht hieronder toont mijn vaardigheden.
							U kunt op de uitklapknop drukken om de specifieke projecten te zien waaraan ik aan heb bijgedragen die gerelateerd zijn aan die vaardigheid.
						</0>`
					}
				}
			}
		}
	}
]);

Component.DefaultProperties(Skills, {
	/**
	 * The Projects to display.
	 * @type {Project[]}
	 */
	projects: [],

	/**
	 * The SkillLevels to display.
	 * @type {SkillLevel[]}
	 */
	skillLevels: []
});
