import {faBook} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {i18next} from "../../../i18next";
import {Skill} from "./Skill";

i18next
	.addResourceBundle("en", "translation", {
		Documentation: {
			Skillsheet: {
				Skills: {
					LaTeXDevelopment: `LaTeX Development`
				}
			}
		}
	}, true)
	.addResourceBundle("nl", "translation", {
		Documentation: {
			Skillsheet: {
				Skills: {
					LaTeXDevelopment: `LaTeX Ontwikkeling`
				}
			}
		}
	}, true);

export const LaTeXDevelopment = new Skill(null, <FontAwesomeIcon icon={faBook}/>);

function update() {
	LaTeXDevelopment.name = i18next.t("Documentation.Skillsheet.Skills.LaTeXDevelopment");
}

i18next.on("languageChanged", language => {
	update();
});

update();
