import {faCopyright, faHeart} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Planet} from "@qub1/spaceshow";
import {Link} from "react-router-dom";
import {Component} from "./Component";
import {ParseTranslate} from "./ParseTranslate";
import {Separator} from "./Separator";

const packageJson = require("../../package.json");

const Wrapper = Component.Footer`
	background: rgba(255, 255, 255, 0.01) radial-gradient(circle, rgba(255, 255, 255, 0.01) 0%, rgba(0, 0, 0, 0.01) 99%);
	box-shadow: 0 0 1em rgba(0, 0, 0, 0.4);
	margin-top: 1em;
	width: 100%;
`;

const Text = Component.Div`
	color: #cecece;
	font-size: 0.8em;
	margin-bottom: 1em;
	text-align: center;
	text-shadow: 0.1em 0.1em black;
`;

const PlanetsList = Component.Wrapper(Link)`
	align-items: center;
	display: flex;
	flex-direction: row;
	gap: 1em;
	justify-content: center;
	margin-top: 1em;
`;

const Heart = Component.Wrapper(FontAwesomeIcon)`
	color: red;
`;

const OverflowUp = Component.Div`
	margin-top: -1em;
	width: 100%;
`;

const Version = Component.Div`
	color: #ccc;
	margin-top: 0.25em;
	font-size: 0.75em;
`;

/**
 * Represents the bottom of the Page.
 */
export const Footer = Component.Translate(class Footer extends Component {
	_onRender() {
		const planets = [];
		for (let planet = 0; planet < 5; ++planet) {
			planets.push(
				Component.Clone(
					Planet.CreateRandom(),
					{
						key: planet,
						diameter: "2em"
					}
				)
			);
		}

		return (
			<Wrapper>
				<OverflowUp>
					<Separator/>
				</OverflowUp>
				<Text>
					{this.translate("Layout.Footer.Copyright")}<br/>
					<br/>
					<ParseTranslate translationKey="Layout.Footer.Hosting">
						<Heart icon={faHeart}/>
						<FontAwesomeIcon icon={faCopyright}/>
					</ParseTranslate>
					<Version>
						{this.translate("Layout.Footer.Version")}
					</Version>
					<PlanetsList to="/Planetarium">
						{planets}
					</PlanetsList>
				</Text>
			</Wrapper>
		);
	}
}, [
	{
		language: "en",
		translations: {
			Layout: {
				Footer: {
					Copyright: `Copyright © ${new Date().getFullYear()} Quincy Bakker. All rights reserved.`,
					Version: `Version ${packageJson.version}`,
					Hosting: `Hosted with <0/> by the Federation of Outer Planets.`
				}
			}
		}
	}, {
		language: "nl",
		translations: {
			Layout: {
				Footer: {
					Copyright: `Copyright © ${new Date().getFullYear()} Quincy Bakker. Alle rechten voorbehouden.`,
					Version: `Versie ${packageJson.version}`,
					Hosting: `Gehost met <0/> door de Federatie van Buitenliggende Planeten.`
				}
			}
		}
	}
]);
