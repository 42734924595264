import {i18next} from "../../../../i18next";
import {CSharpDevelopment} from "../../../Skillsheet/Skills/CSharpDevelopment";
import {Dutch} from "../../../Skillsheet/Skills/Dutch";
import {English} from "../../../Skillsheet/Skills/English";
import {GameDevelopment} from "../../../Skillsheet/Skills/GameDevelopment";
import {GitDevelopment} from "../../../Skillsheet/Skills/GitDevelopment";
import {GitLabCIDevelopment} from "../../../Skillsheet/Skills/GitLabCIDevelopment";
import {KotlinDevelopment} from "../../../Skillsheet/Skills/KotlinDevelopment";
import {LaTeXDevelopment} from "../../../Skillsheet/Skills/LaTeXDevelopment";
import {SpringDevelopment} from "../../../Skillsheet/Skills/SpringDevelopment";
import {UnityDevelopment} from "../../../Skillsheet/Skills/UnityDevelopment";
import {Project} from "../Project";
import Level from "./Level.png";
import Poster from "./Poster.png";

i18next
	.addResourceBundle("en", "translation", {
		Documents: {
			Articles: {
				Projects: {
					SoKicking: {
						Description: `
							SoKicking is an augmented reality mobile game where players compete to finish various courses while avoiding virtual hazards.
							The holes in a course are positioned in real world locations, so for players to win a match they will have to compete in real life as well.
						`,
						RoleDescription: `I worked on the server-side API that handles game data and events as well as user authentication.`
					}
				}
			}
		}
	}, true)
	.addResourceBundle("nl", "translation", {
		Documents: {
			Articles: {
				Projects: {
					SoKicking: {
						Description: `
							SoKicking is een augmented reality mobiele game waarbij spelers het tegen elkaar op nemen om verscheidene banen te voltooien terwijl ze gevaarlijke obstakels vermijden.
							De holes in een baan zijn gepositioneerd in de echte wereld, dus als spelers een potje willen winnen zullen ze in het echt tegen elkaar moeten opnemen.
						`,
						RoleDescription: `Ik heb aan de server-side API gewerkt die game data en evenementen beheert, en ook gebruikers authenticatie.`
					}
				}
			}
		}
	}, true);

export const SoKicking = new Project("SoKicking", null, null, new URL("http://www.cs.uu.nl/docs/vakken/sp/voorjaar2018/SoKicking_ProjectPlan.pdf"), [
	CSharpDevelopment,
	Dutch,
	English,
	GameDevelopment,
	GitDevelopment,
	GitLabCIDevelopment,
	KotlinDevelopment,
	LaTeXDevelopment,
	SpringDevelopment,
	UnityDevelopment
], [
	Poster,
	Level
]);

function update() {
	SoKicking.description = i18next.t("Documents.Articles.Projects.SoKicking.Description");
	SoKicking.roleDescription = i18next.t("Documents.Articles.Projects.SoKicking.RoleDescription");
}

i18next.on("languageChanged", language => {
	update();
});

update();
