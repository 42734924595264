import {Contact, EmailAddress} from "@qub1/qulibjs";
import {Quincy} from "../../Animals/Humans/Quincy";
import {i18next} from "../../i18next";
import ProfilePicture from "./Qub1.jpeg";

i18next
	.addResourceBundle("en", "translation", {
		Communication: {
			Contacts: {
				Qub1: {
					Biography: `
						I have been passionate about using science and programming to create and enhance the digital world for as long as I can remember.
						Figuring out how things work and then building on that knowledge is one of my favorite activities, and using this knowledge to work on projects that move humanity forward is my lifelong goal.
						
						In my spare time I enjoy working on my personal projects among which are a C++ voxel based 3D simulation engine and my home media/game server.
						I also like working on full-stack web development, and as such I have developed and manage the website of a family business, as well as my own portfolio and various other small personal websites and APIs.
						
						In short, I have a passion for development and science and I hope to turn these passions into a career.
					`
				}
			}
		}
	}, true)
	.addResourceBundle("nl", "translation", {
		Communication: {
			Contacts: {
				Qub1: {
					Biography: `
						Voor zo lang ik me kan herinneren ben ik gepassioneerd geweest over het combineren van wetenschap en programmeren om de digitale wereld uit te breiden en dingen te creëren.
						Uitzoeken hoe dingen in elkaar zitten en dan vervolgens verder bouwen op die kennis is een van mijn favoriete activiteiten, en deze kennis gebruiken om de mensheid vooruit te helpen is mijn levenslange doel.

						In mijn vrije tijd werk ik graag aan persoonlijke projecten waaronder een C++ 3D voxel simulator en mijn eigen media/game server.
						Ik werk ook graag aan full-stack web development, en heb dan ook de website van een familiebedrijf ontworpen en beheerd, net als mijn eigen portfolio website en verscheidene andere kleinere persoonlijke websites en APIs.

						Kortom, ik heb een passie voor dingen ontwikkelen en de wetenschap en ik hoop deze passie om te zetten in een carrière.
					`
				}
			}
		}
	}, true);

export const Qub1 = new Contact(
	Quincy,
	ProfilePicture,
	new EmailAddress("qub1995", "gmail.com"),
	null
);

function update() {
	Qub1.biography = i18next.t("Communication.Contacts.Qub1.Biography");
}
i18next.on("languageChanged", language => {
	update();
});
update();
