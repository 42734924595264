import {Component} from "../Layout/Component";
import {Separator} from "../Layout/Separator";

const Wrapper = Component.Figure`
	box-sizing: border-box;
	font-size: 0.9em;
	padding: 1em 2em;
	text-align: center;
	width: 100%;
`;

const Text = Component.Blockquote`
	font-style: italic;

	:before {
		content: open-quote;
	}

	:after {
		content: close-quote;
	}
`;

const Caption = Component.Figcaption`
	color: #bbb;
	font-size: 0.9em;
	margin-top: 0.3em;
`;

/**
 * A quote from an external source.
 */
export class Quote extends Component {
	_onRender() {
		return (
			<Wrapper>
				<Separator/>
				<Text cite={this.properties.source}>
					{this.children}
				</Text>
				<Caption>- {this.properties.author} {this.properties.date}</Caption>
				<Separator/>
			</Wrapper>
		);
	}
}
