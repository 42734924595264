import EnglishIcon from "language-icons/icons/en.svg";
import NederlandsIcon from "language-icons/icons/nl.svg";
import {i18next} from "../i18next";
import {Component} from "./Component";

/**
 * The menu with language options.
 */
const Menu = Component.Ul`
	align-items: center;
	display: flex;
	flex-direction: row;
	gap: 0.5em;
	justify-content: center;
	list-style-type: none;

	@media (max-width: 250px) {
		flex-direction: column;
	}
`;

/**
 * A button that switches languages.
 */
const LanguageButton = Component.Button`
	align-items: center;
	border: none;
	background: none;
	//background: rgba(255, 255, 255, 0.05) radial-gradient(circle, rgba(255, 255, 255, 0.05) 0%, rgba(0, 0, 0, 0.05) 99%);
	//border-radius: 1em;
	//border: 1px solid black;
	//box-shadow: 4px 4px rgba(0, 0, 0, 0.5);
	box-sizing: border-box;
	color: white;
	display: flex;
	flex-direction: row;
	gap: 0.3em;
	justify-content: center;
	padding: 0.3em 0.6em;

	:active {
		//background: rgba(255, 255, 255, 0.3) radial-gradient(circle, rgba(255, 255, 255, 0.3) 0%, rgba(0, 0, 0, 0.3) 99%);
		font-weight: bold;
	}
`;

/**
 * A language icon.
 */
const LanguageIcon = Component.Img`
	border-radius: 100%;
	height: 1.3em;
	width: 1.3em;
`;

/**
 * Switches languages.
 */
export const LanguageSwitcher = Component.Translate(class LanguageSwitcher extends Component {
	_onRender() {
		const languages = {
			en: {
				nativeName: i18next.getFixedT("en", "translation")("languages.english"),
				icon: EnglishIcon
			},
			nl: {
				nativeName: i18next.getFixedT("nl", "translation")("languages.dutch"),
				icon: NederlandsIcon
			}
		};

		return (
			<Menu>
				{Object.keys(languages)
					.map((language) => (
						<li key={language}>
							<LanguageButton style={{fontWeight: this.properties.i18n.language === language ? "bold" : "normal", color: this.properties.i18n.language === language ? "#7fcaff" : ""}} type="submit" onClick={() => this.props.i18n.changeLanguage(language)}>
								<LanguageIcon src={languages[language].icon}/>
								{languages[language].nativeName}
							</LanguageButton>
						</li>
					))}
			</Menu>
		);
	}
}, [
	{
		language: "en",
		translations: {
			languages: {
				english: `English`,
				dutch: `Dutch`
			}
		}
	}, {
		language: "nl",
		translations: {
			languages: {
				english: `Engels`,
				dutch: `Nederlands`
			}
		}
	}
]);
