import {faAward, faPhotoVideo, faWrench} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Link} from "react-router-dom";
import {Component} from "../../Layout/Component";
import {Content} from "../../Layout/Content";
import {ParseTranslate} from "../../Layout/ParseTranslate";
import {Section} from "../../Layout/Section";
import {Article} from "./Article";

const Overview = Component.Div`
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	flex-wrap: wrap;
	gap: 1em;
	text-align: center;
`;

const OverviewOption = Component.Wrapper(Section)`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
`;

const OverviewIcon = Component.Div`
	font-size: 4em;
`;

/**
 * The homepage.
 */
export const Home = Component.Translate(class Home extends Component {
	_afterMount() {
		document.title = this.translate("Documents.Articles.Home.Title");
	}

	_onRender() {
		return (
			<Article>
				<Content>
					<Section title={this.translate("Documents.Articles.Home.Title")}>
						<ParseTranslate translationKey="Documents.Articles.Home.Welcome">
							<p/><p/>
						</ParseTranslate>
					</Section>
					<Overview>
						<Link to="/Portfolio">
							<OverviewOption title={this.translate("Navigation.Portfolio")}>
								<OverviewIcon>
									<FontAwesomeIcon icon={faPhotoVideo}/>
								</OverviewIcon>
							</OverviewOption>
						</Link>
						<Link to="/Skills">
							<OverviewOption title={this.translate("Navigation.Skills")}>
								<OverviewIcon>
									<FontAwesomeIcon icon={faWrench}/>
								</OverviewIcon>
							</OverviewOption>
						</Link>
						<Link to="/CurriculumVitae">
							<OverviewOption title={this.translate("Navigation.CurriculumVitae")}>
								<OverviewIcon>
									<FontAwesomeIcon icon={faAward}/>
								</OverviewIcon>
							</OverviewOption>
						</Link>
					</Overview>
				</Content>
			</Article>
		);
	}
}, [
	{
		language: "en",
		translations: {
			Documents: {
				Articles: {
					Home: {
						Title: `Welcome!`,
						Welcome: `
							<0>
								You have reached Qub1.com.
								No one knows how you ended up here, but make yourself at home while you're here!
							</0>
							<1>You can explore the site using the menu on top or use the quick-navigation below.</1>
						`
					}
				}
			}
		}
	}, {
		language: "nl",
		translations: {
			Documents: {
				Articles: {
					Home: {
						Title: `Welkom!`,
						Welcome: `
							<0>
								Je hebt Qub1.com bereikt.
								Niemand weet hoe je hier bent beland, maar maak jezelf comfortabel terwijl je er bent!
							</0>
							<1>Je kan de site verkennen met het menu bovenaan of met het snelle menu hieronder.</1>
						`
					}
				}
			}
		}
	}
]);
