import {Redirect, Route, Switch} from "react-router-dom";
import {About} from "../Documents/Articles/About";
import {CurriculumVitae as CurriculumVitaeArticle} from "../Documents/Articles/CurriculumVitae";
import {Home} from "../Documents/Articles/Home";
import {NotFound} from "../Documents/Articles/NotFound";
import {Planetarium} from "../Documents/Articles/Planetarium";
import {Portfolio} from "../Documents/Articles/Portfolio";
import {ProjectArticle} from "../Documents/Articles/Projects/ProjectArticle";
import {Qub1com} from "../Documents/Articles/Projects/Qub1com/Qub1com";
import {Skills} from "../Documents/Articles/Skills";
import {CurriculumVitaeSpaceshow} from "../Documents/CurriculumVitae/CurriculumVitaeSpaceshow";
import {QuincyBakkerCurriculumVitae} from "../Documents/CurriculumVitae/QuincyBakkerCurriculumVitae";
import {SpaceInterface} from "../Multimedia/Simulations/3D/Space/SpaceInterface";
import {CenterSection} from "./CenterSection";
import {Component} from "./Component";
import {Footer} from "./Footer";
import {Header} from "./Header";
import {HiddenTopSection} from "./HiddenTopSection";
import {Page} from "./Page";

const curriculumVitae = QuincyBakkerCurriculumVitae;

export class Application extends Component {
	_onRender() {
		return (
			<Page>
				<Switch>
					<Route exact path="/CurriculumVitae">
						<HiddenTopSection key="CurriculumVitae">
							<CurriculumVitaeSpaceshow curriculumVitae={curriculumVitae}/>
						</HiddenTopSection>
					</Route>
					<Route exact path="/Planetarium">
						<HiddenTopSection key="Planetarium">
							<SpaceInterface/>
						</HiddenTopSection>
					</Route>
				</Switch>
				<CenterSection>
					<Header/>
					<Switch>
						<Route exact path="/Home">
							<Home/>
						</Route>
						<Route exact path="/">
							<Redirect to="/Home"/>
						</Route>
						<Route exact path="/Portfolio">
							<Portfolio projects={curriculumVitae.projects}/>
						</Route>
						<Route exact path="/Skills">
							<Skills projects={curriculumVitae.projects} skillLevels={curriculumVitae.skillLevels}/>
						</Route>
						<Route path="/Project/:id" render={properties => <ProjectArticle project={curriculumVitae.projects.filter(project => project.name.replaceAll(/\s/g, "") === properties.match.params.id)[0]}/>}/>
						<Route exact path="/CurriculumVitae">
							<CurriculumVitaeArticle curriculumVitae={curriculumVitae}/>
						</Route>
						<Route exact path="/About">
							<About contact={curriculumVitae.contact} project={Qub1com}/>
						</Route>
						<Route exact path="/Planetarium">
							<Planetarium/>
						</Route>
						<Route path="*" status="404">
							<NotFound/>
						</Route>
					</Switch>
					<Footer/>
				</CenterSection>
			</Page>
		);
	}
}
