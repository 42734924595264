import path from "path";
import {Component} from "../../../Layout/Component";
import {Content} from "../../../Layout/Content";
import {Section} from "../../../Layout/Section";
import {Separator} from "../../../Layout/Separator";
import {Sidebar} from "../../../Layout/Sidebar";
import {Gallery} from "../../../Multimedia/Gallery";
import {SkillsOverview} from "../../Skillsheet/SkillsOverview";
import {Article} from "../Article";

const Details = Component.Div`
	display: flex;
	flex-direction: column;
	gap: 1em;
	justify-content: space-evenly;

	h2 {
		font-size: 1.2em;
	}
`;

/**
 * A general article in the main section of the website.
 */
export const ProjectArticle = Component.Translate(class ProjectArticle extends Component {
	_afterMount() {
		document.title = this.properties.project.name;
	}

	_onRender() {
		return (
			<Article>
				<Content>
					<Section title={this.properties.project.name}>
						{this.properties.project.description}
					</Section>
					<Section title={this.translate("Documents.Articles.Projects.ProjectArticle.Role")}>
						{this.properties.project.roleDescription}
					</Section>
					{this.properties.project.multimedia.length > 0 && (
						<>
							<Separator/>
							<Gallery>
								{this.properties.project.multimedia.map((media, index) => {
									const fileExtension = path.extname(media).toLowerCase();

									const imageExtensions = [
										".ico",
										".jpeg",
										".jpg",
										".png",
										".svg",
										".webp"
									];
									const imageMimetypes = [
										"image/jpeg",
										"image/png"
									];
									const videoExtensions = [
										".mp4"
									];

									if (imageExtensions.includes(fileExtension) || imageMimetypes.some(mimetype => media.includes(mimetype))) {
										return (
											<img key={index} src={media}/>
										);
									} else if (videoExtensions.includes(fileExtension)) {
										return (
											<video key={index} controls>
												<source src={media}/>
											</video>
										);
									} else {
										return (
											<a key={index} href={media}>{media}</a>
										);
									}
								})}
							</Gallery>
						</>
					)}
				</Content>
				<Sidebar>
					<Section title={this.translate("Documents.Articles.Projects.ProjectArticle.Details")}>
						<Details>
							<div>
								<h2>{this.translate("Documents.Articles.Projects.ProjectArticle.Skills")}</h2>
								<SkillsOverview skills={this.properties.project.skills}/>
							</div>
							<div>
								<h2>{this.translate("Documents.Articles.Projects.ProjectArticle.Homepage")}</h2>
								<a href={this.properties.project.homepage} target="_blank">{this.translate("Documents.Articles.Projects.ProjectArticle.Link")}</a>
							</div>
						</Details>
					</Section>
				</Sidebar>
			</Article>
		);
	}
}, [
	{
		language: "en",
		translations: {
			Documents: {
				Articles: {
					Projects: {
						ProjectArticle: {
							Role: `Role`,
							Details: `Details`,
							Skills: `Skills`,
							Homepage: `Homepage`,
							Link: `Link`
						}
					}
				}
			}
		}
	}, {
		language: "nl",
		translations: {
			Documents: {
				Articles: {
					Projects: {
						ProjectArticle: {
							Role: `Rol`,
							Details: `Details`,
							Skills: `Vaardigheden`,
							Homepage: `Thuispagina`,
							Link: `Link`
						}
					}
				}
			}
		}
	}
]);
