import {Component} from "../../Layout/Component";

/**
 * The outer wrapper.
 */
const Wrapper = Component.Article`
	box-sizing: border-box;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	flex: 1;
	gap: 1em;
	justify-content: center;
	max-width: 1200px;
	padding: 0 1em;
	width: 100%;

	@media (max-width: 500px) {
		align-items: center;
		flex-direction: column;
		padding: 0;
	}
	
	a {
		color: #61dafb;
	}
`;

/**
 * A general article in the main section of the website.
 */
export class Article extends Component {
	_onRender() {
		return (
			<Wrapper>
				{this.children}
			</Wrapper>
		);
	}
}
