import {i18next} from "../../../../i18next";
import {CSharpDevelopment} from "../../../Skillsheet/Skills/CSharpDevelopment";
import {English} from "../../../Skillsheet/Skills/English";
import {Gaming} from "../../../Skillsheet/Skills/Gaming";
import {VisualStudio} from "../../../Skillsheet/Skills/VisualStudio";
import {Project} from "../Project";
import Screenshot1 from "./Screenshot1.webp";
import Screenshot2 from "./Screenshot2.webp";
import Screenshot3 from "./Screenshot3.webp";
import Screenshot4 from "./Screenshot4.webp";

i18next
	.addResourceBundle("en", "translation", {
		Documents: {
			Articles: {
				Projects: {
					GrapplingOptions: {
						Description: `
							Grappling Options is a modification for Just Cause 3 that modifies the behavior of the grappling hook to allow different maximum ranges.
							The modification was also featured in the game magazine Kotaku: https://kotaku.com/just-cause-3-mod-gives-you-an-infinite-grappling-hook-1751686244
						`,
						RoleDescription: `
							I created the modification.
						`
					}
				}
			}
		}
	}, true)
	.addResourceBundle("nl", "translation", {
		Documents: {
			Articles: {
				Projects: {
					GrapplingOptions: {
						Description: `
							Grappling Options is een modificatie voor Just Cause 3 die het gedrag van de grijphaak aanpast om het maximum bereik aan te passen.
							De modificatie is ook getoond in het game magazine Kotaku: https://kotaku.com/just-cause-3-mod-gives-you-an-infinite-grappling-hook-1751686244
						`,
						RoleDescription: `
							Ik heb de modificatie gemaakt.
						`
					}
				}
			}
		}
	}, true);

export const GrapplingOptions = new Project("Grappling Options", null, null, new URL("https://videogamemods.com/justcause3/mods/grappling-options-infinite-range-double-range-etc/"), [
	CSharpDevelopment,
	English,
	Gaming,
	VisualStudio
], [
	Screenshot1,
	Screenshot2,
	Screenshot3,
	Screenshot4
]);

function update() {
	GrapplingOptions.description = i18next.t("Documents.Articles.Projects.GrapplingOptions.Description");
	GrapplingOptions.roleDescription = i18next.t("Documents.Articles.Projects.GrapplingOptions.RoleDescription");
}

i18next.on("languageChanged", language => {
	update();
});

update();
