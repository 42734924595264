import {Component} from "./Component";

const Wrapper = Component.Section`
	align-items: center;
	display: flex;
	flex: 1;
	flex-direction: column;
	gap: 1em;
	justify-content: flex-start;

	@media (max-width: 500px) {
		padding: 0;
	}
`;

/**
 * Represents the main content of the Body.
 */
export class Content extends Component {
	_onRender() {
		return (
			<Wrapper>
				{this.children}
			</Wrapper>
		);
	}
}
