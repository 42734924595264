import {faCode} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {i18next} from "../../../i18next";
import {Skill} from "./Skill";

i18next
	.addResourceBundle("en", "translation", {
		Documentation: {
			Skillsheet: {
				Skills: {
					MyBatisORMDevelopment: `MyBatis ORM Development`
				}
			}
		}
	}, true)
	.addResourceBundle("nl", "translation", {
		Documentation: {
			Skillsheet: {
				Skills: {
					MyBatisORMDevelopment: `MyBatis ORM Ontwikkeling`
				}
			}
		}
	}, true);

export const MyBatisORMDevelopment = new Skill(null, <FontAwesomeIcon icon={faCode}/>);

function update() {
	MyBatisORMDevelopment.name = i18next.t("Documentation.Skillsheet.Skills.MyBatisORMDevelopment");
}

i18next.on("languageChanged", language => {
	update();
});

update();
