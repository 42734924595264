import "@fontsource/lobster";
import "@fontsource/oswald";
import {faAward, faCertificate} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {darken} from "polished";
import randomColor from "randomcolor";
import {Component} from "../../Layout/Component";

const width = "16em";

/**
 * The outer wrapper.
 */
const Wrapper = Component.Div`
	background: white;
	border: 0.01em solid black;
	box-sizing: border-box;
	color: black;
	height: calc(1.4142 * ${width});
	padding: 1.75em;
	position: relative;
	text-shadow: none;
	width: ${width};

	h1, h2, h3, h4, h5, h6 {
		margin-bottom: 1em;
	}
`;

const Title = Component.H1`
	color: ${properties => properties.titleColor};
	font-family: Lobster, sans-serif;
	font-size: 1.5em;
	text-shadow: 0.05em 0.05em ${properties => darken(0.3, properties.titleColor)};
`;

const Subtitle = Component.H2`
	font-family: Oswald, sans-serif;
	font-size: 1.25em;
`;

const Place = Component.H3`
	font-size: 1em;
`;

const Dates = Component.Small`
	display: block;
	font-style: italic;
	margin-bottom: 1em;
`;

/**
 * The certificate banner.
 */
const CertificateIcon = Component.Div`
	color: gold;
	font-size: 5em;
	position: absolute;
	left: -0.5em;
	top: calc(50% - 0.5em);
`;

/**
 * The certificate banner.
 */
const CertificateRibbon = Component.Div`
	color: red;
	font-size: 6em;
	position: absolute;
	left: -0.38em;
	top: calc(50% - 0.37em);
`;

/**
 * The name.
 */
const Name = Component.Div`
	color: darkblue;
	font-family: Lobster, sans-serif;
	position: absolute;
	bottom: 2em;
	right: 2em;
`;

/**
 * A mini-document for display purposes.
 */
export class Certificate extends Component {
	_onRender() {
		return (
			<Wrapper>
				<Title titleColor={this.properties.titleColor === "random" ? randomColor({
					seed: this.properties.title,
					luminosity: "bright",
					format: "hex"
				}) : this.properties.titleColor}>{this.properties.title}</Title>
				<Subtitle>{this.properties.subtitle}</Subtitle>
				<Place>{this.properties.place}</Place>
				<Dates>{this.properties.dates}</Dates>
				<Name>{this.properties.name}</Name>

				<CertificateRibbon><FontAwesomeIcon icon={faAward}/></CertificateRibbon>
				<CertificateIcon><FontAwesomeIcon icon={faCertificate}/></CertificateIcon>
			</Wrapper>
		);
	}
}

Component.DefaultProperties(Certificate, {
	/**
	 * The color of the title.
	 * @type {string}
	 */
	titleColor: "random",

	/**
	 * The title.
	 * @type {string}
	 */
	title: "",

	/**
	 * The subtitle.
	 * @type {string}
	 */
	subtitle: "",

	/**
	 * The place.
	 * @type {string}
	 */
	place: "",

	/**
	 * The date.
	 * @type {string}
	 */
	dates: "",

	/**
	 * The name.
	 * @type {string}
	 */
	name: ""
});
