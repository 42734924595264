import "@fontsource/open-sans";
import {faExpand, faPrint} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Component} from "../Layout/Component";
import {Page} from "./Page";

/**
 * The outer wrapper.
 */
const DocumentWrapper = Component.Article`
	display: block;
	height: 100%;
	max-height: 100vh;
	max-width: 100vw;
	overflow: auto;
	position: relative;
`;

/**
 * The inner wrapper.
 */
const PageWrapper = Component.Div`
	box-sizing: border-box;
	color: black;
	font-family: "Open Sans", sans-serif;
	font-size: 11px;
	height: 100%;
	padding: 1em 2em;
	width: 100%;

	> * {
		margin: 0 auto 1em auto;
	}
`;

const ControlsPanel = Component.Div`
	align-items: center;
	display: flex;
	flex-direction: column;
	float: left;
	gap: 0.5em;
	justify-content: center;
	left: 3em;
	position: sticky;
	top: 2em;
	z-index: 100;
`;

/**
 * Prints the Document.
 */
const ControlButton = Component.Button`
	background: rgba(0, 0, 0, 0.4);
	border-radius: 100%;
	border: none;
	color: white;
	font-size: 1.25em;
	height: 2em;
	width: 2em;

	:hover {
		background: rgba(0, 0, 0, 0.3);
	}

	:active {
		background: rgba(0, 0, 0, 0.2);
	}
`;

/**
 * Represents a collection of Pages.
 */
export class Document extends Component {
	/**
	 * Creates a new Document.
	 * @param properties The properties.
	 */
	constructor(properties) {
		super(properties);

		this.state = {
			childHeights: null,
			childReferences: this.props.children.map(child => Component.CreateReference()),
			pageHeight: null,
			pagesReference: Component.CreateReference(),
			referencePage: Component.CreateReference()
		};
	}

	_afterMount() {
		// Calculate the child heights
		const childHeights = [];
		for (let child of this.state.childReferences) {
			childHeights.push(child.current.scrollHeight);
		}

		// Calculate the page height
		const pageHeight = Component.CalculateElementInnerHeight(this.state.referencePage.current);

		// Update the state so the elements are re-rendered
		if (this.state.childHeights === null && childHeights.length > 0) {
			this.setState({
				childHeights: childHeights
			});
		}
		if (this.state.pageHeight === null && pageHeight !== undefined) {
			this.setState({
				pageHeight: pageHeight
			});
		}
	}

	/**
	 * Clones the Document to a new Window.
	 * @return {Window} The Window.
	 * @private
	 */
	_cloneToWindow() {
		// Create new Window to print in
		const clonedWindow = window.open("", "");

		// Clone the styles
		const styles = Array.from(document.querySelectorAll(`style`));

		// Clone the link resources
		const links = Array.from(document.querySelectorAll(`link`));

		// Clone the elements
		const clonedElements = Array.from(document.querySelectorAll(`#print`)).map(element => Component.CloneElement(element, true));

		// Clone the print elements to the new window
		clonedWindow.document.write(`
			<html>
				<head>
					<title>Document</title>
					${styles.map(style => style.outerHTML).join("")}
					${links.map(link => link.outerHTML).join("")}
				</head>
				<body>
					${clonedElements.map(element => element.outerHTML).join("")}
				</body>
			</html>
		`);

		// Add formatting
		const style = clonedWindow.document.createElement(`style`);
		style.appendChild(clonedWindow.document.createTextNode(`
			#print {
				width: 100% !important;
			}
			
			#print #printPage {
				margin: 0 auto 1em auto !important;
			}
		`));
		clonedWindow.document.head.appendChild(style);

		// Change page style when printing
		const changePrintMode = enabled => {
			if (enabled) {
				// Remove browser-added margins as they are already handled by us as well as page styling
				const printPageStyle = clonedWindow.document.createElement(`style`);
				printPageStyle.id = "printPageStyle";
				printPageStyle.appendChild(clonedWindow.document.createTextNode(`
					* {
						color-adjust: exact !important; /* Forces CSS backgrounds to be printed */
					}
					
					@page {
						margin: 0 !important;
					}
					
					#print #printPage {
						box-shadow: none !important;
						page-break-after: always !important;
					}
				`));
				clonedWindow.document.head.appendChild(printPageStyle);
			} else {
				// Remove the print style
				clonedWindow.document.querySelector("#printPageStyle").remove();
			}
		};
		clonedWindow.addEventListener("beforeprint", event => changePrintMode(true));
		clonedWindow.addEventListener("afterprint", event => changePrintMode(false));

		// Finish writing to the document
		clonedWindow.document.close();

		return clonedWindow;
	}

	fullScreen() {
		// Clone the window
		const clonedWindow = this._cloneToWindow();

		// Switch to the window
		clonedWindow.focus();

		return clonedWindow;
	}

	print() {
		// Go to a fullscreen window
		const fullScreenWindow = this.fullScreen();

		// Close the window after printing
		fullScreenWindow.addEventListener("afterprint", event => fullScreenWindow.close());

		// Wait for the window to load
		fullScreenWindow.addEventListener("load", event => {
			// Print the window
			fullScreenWindow.print();
		});
	}

	_onRender() {
		return (
			<DocumentWrapper>
				<ControlsPanel>
					<ControlButton onClick={this.print.bind(this)}>
						<FontAwesomeIcon icon={faPrint}/>
					</ControlButton>
					<ControlButton onClick={this.fullScreen.bind(this)}>
						<FontAwesomeIcon icon={faExpand}/>
					</ControlButton>
				</ControlsPanel>
				<PageWrapper ref={this.state.pagesReference} id="print">
					{(
						() => {
							// Check if the child sizes have already been computed
							if (this.state.childHeights !== null && this.state.pageHeight !== null) {
								// If so, use the sizes to render the pages
								const maximumPageHeight = this.state.pageHeight;
								const pages = [];
								let currentPageChildren = [];
								let currentPageHeight = 0;

								// Loop the children to generate the pages
								for (let childIndex = 0; childIndex < this.props.children.length; ++childIndex) {
									const child = this.props.children[childIndex];
									const childHeight = this.state.childHeights[childIndex];

									// Check if the child fits on the current page
									if (currentPageHeight + childHeight > maximumPageHeight) {
										// If not, finish the current page and add it to the list of pages
										pages.push(<Page key={pages.length}>{currentPageChildren}</Page>);

										// Reset the variables
										currentPageChildren = [];
										currentPageHeight = 0;
									}

									// Add the child
									currentPageHeight += childHeight;
									currentPageChildren.push(<div key={currentPageChildren.length}>{child}</div>);
								}

								// Add the last page
								if (currentPageChildren.length > 0) {
									pages.push(<Page key={pages.length}>{currentPageChildren}</Page>);
								}

								// Render the pages
								return pages;
							} else {
								// If not, render the children so the sizes can be calculated
								return (
									<Page reference={this.state.referencePage}>
										This is the reference page.
										It should not render.
										If it does, there might be an issue with the code.
										{this.children.map((child, index) => (
											<div key={index} ref={this.state.childReferences[index]}>{child}</div>
										))}
									</Page>
								);
							}
						}
					)()}
				</PageWrapper>
			</DocumentWrapper>
		);
	}
}
