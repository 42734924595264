import {Vector} from "@qub1/qulibjs";
import {Camera, Interface} from "@qub1/venginejs";
import {Component} from "../../../../Layout/Component";
import {Space} from "./Entities/Space";

const Wrapper = Component.Div`
	width: 100%;
	height: 100vh;
`;

export class SpaceInterface extends Component {
	constructor(properties) {
		super(properties);

		// The camera to use
		const camera = new Camera();
		// camera.coordinates = new Vector(0, 0, 6371e3/2 + 10);
		camera.coordinates = new Vector(0, 0, 10 / 2 + 50);

		this.state = {
			camera: camera,
			universe: new Space(camera)
		};
	}

	_onRender() {
		return (
			<Wrapper>
				<Interface universe={this.state.universe} camera={this.state.camera}/>
			</Wrapper>
		);
	}
}
