import {Planet} from "@qub1/spaceshow";
import {Component} from "./Component";

/**
 * The outer wrapper.
 */
const Wrapper = Component.Div`
	align-items: center;
	display: flex;
	flex-direction: row;
	gap: 1em;
	height: 1em;
	justify-content: center;
	margin: 0.5em 0;
	width: 100%;
`;

/**
 * The lines around the center extending outward.
 */
const Line = Component.Div`
	background: #b78a21;
	border-radius: 100%;
	height: 0.1em;
	width: 40%;
`;

/**
 * Separates content.
 */
export class Separator extends Component {
	_onRender() {
		return (
			<Wrapper>
				<Line/>
				{Component.Clone(Planet.CreateRandom(), {
					diameter: "1.5em"
				})}
				<Line/>
			</Wrapper>
		);
	}
}
