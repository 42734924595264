import {Qub1} from "../../Communication/Contacts/Qub1";
import {Degree} from "../../Education/Degree";
import {FonsVitaeLyceum} from "../../Education/Institutions/FonsVitaeLyceum";
import {UniversiteitUtrecht} from "../../Education/Institutions/UniversiteitUtrecht";
import {UniversiteitVanAmsterdam} from "../../Education/Institutions/UniversiteitVanAmsterdam";
import {VrijeUniversiteit} from "../../Education/Institutions/VrijeUniversiteit";
import {Adyen} from "../../Work/Companies/Adyen";
import {HairstudioJoyce} from "../../Work/Companies/HairstudioJoyce";
import {PluhZ} from "../../Work/Companies/PluhZ";
import {SURF} from "../../Work/Companies/SURF";
import {Job} from "../../Work/Job";
import {EnergyAwareRuntime} from "../Articles/Projects/EnergyAwareRuntime";
import {EnergyManager} from "../Articles/Projects/EnergyManager/EnergyManager";
import {GrapplingOptions} from "../Articles/Projects/Grappling-Options/Grappling-Options";
import {HairstudioJoyce as HairstudioJoyceProject} from "../Articles/Projects/Hairstudio-Joyce/Hairstudio-Joyce";
import {LiquidCraft} from "../Articles/Projects/LiquidCraft";
import {Logness} from "../Articles/Projects/Logness/Logness";
import {MineQraft} from "../Articles/Projects/MineQraft/MineQraft";
import {MultiLink} from "../Articles/Projects/MultiLink/MultiLink";
import {Qub1com} from "../Articles/Projects/Qub1com/Qub1com";
import {QuLib} from "../Articles/Projects/QuLib";
import {RoadMapper} from "../Articles/Projects/RoadMapper/RoadMapper";
import {SoKicking} from "../Articles/Projects/SoKicking/SoKicking";
import {SortPlusPlus} from "../Articles/Projects/SortPlusPlus/SortPlusPlus";
import {Spaceshow} from "../Articles/Projects/Spaceshow/Spaceshow";
import {Stickuino} from "../Articles/Projects/Stickuino/Stickuino";
import {Vengine} from "../Articles/Projects/Vengine";
import {WolfPackageManager} from "../Articles/Projects/WolfPackageManager";
import {AgileDevelopment} from "../Skillsheet/Skills/AgileDevelopment";
import {AnsibleDevelopment} from "../Skillsheet/Skills/AnsibleDevelopment";
import {ArduinoDevelopment} from "../Skillsheet/Skills/ArduinoDevelopment";
import {BashDevelopment} from "../Skillsheet/Skills/BashDevelopment";
import {BoostDevelopment} from "../Skillsheet/Skills/BoostDevelopment";
import {CDevelopment} from "../Skillsheet/Skills/CDevelopment";
import {ChapelDevelopment} from "../Skillsheet/Skills/ChapelDevelopment";
import {Climbing} from "../Skillsheet/Skills/Climbing";
import {CMakeDevelopment} from "../Skillsheet/Skills/CMakeDevelopment";
import {CPlusPlusDevelopment} from "../Skillsheet/Skills/CPlusPlusDevelopment";
import {CSharpDevelopment} from "../Skillsheet/Skills/CSharpDevelopment";
import {CSS3Development} from "../Skillsheet/Skills/CSS3Development";
import {CUDADevelopment} from "../Skillsheet/Skills/CUDADevelopment";
import {DataTablesDevelopment} from "../Skillsheet/Skills/DataTablesDevelopment";
import {DebianLinux} from "../Skillsheet/Skills/DebianLinux";
import {DockerDevelopment} from "../Skillsheet/Skills/DockerDevelopment";
import {DoxygenDocumentation} from "../Skillsheet/Skills/DoxygenDocumentation";
import {Driving} from "../Skillsheet/Skills/Driving";
import {Dutch} from "../Skillsheet/Skills/Dutch";
import {English} from "../Skillsheet/Skills/English";
import {ExpressDevelopment} from "../Skillsheet/Skills/ExpressDevelopment";
import {French} from "../Skillsheet/Skills/French";
import {GameDevelopment} from "../Skillsheet/Skills/GameDevelopment";
import {Gaming} from "../Skillsheet/Skills/Gaming";
import {German} from "../Skillsheet/Skills/German";
import {GitDevelopment} from "../Skillsheet/Skills/GitDevelopment";
import {GitLabCIDevelopment} from "../Skillsheet/Skills/GitLabCIDevelopment";
import {GradleDevelopment} from "../Skillsheet/Skills/GradleDevelopment";
import {Guitar} from "../Skillsheet/Skills/Guitar";
import {HTML5Development} from "../Skillsheet/Skills/HTML5Development";
import {JavaDevelopment} from "../Skillsheet/Skills/JavaDevelopment";
import {JavaScriptDevelopment} from "../Skillsheet/Skills/JavaScriptDevelopment";
import {JetbrainsSoftware} from "../Skillsheet/Skills/JetbrainsSoftware";
import {JSDocDocumentation} from "../Skillsheet/Skills/JSDocDocumentation";
import {JupyterDevelopment} from "../Skillsheet/Skills/JupyterDevelopment";
import {KotlinDevelopment} from "../Skillsheet/Skills/KotlinDevelopment";
import {KubernetesDevelopment} from "../Skillsheet/Skills/KubernetesDevelopment";
import {LaTeXDevelopment} from "../Skillsheet/Skills/LaTeXDevelopment";
import {LibreOffice} from "../Skillsheet/Skills/LibreOffice";
import {MicrosoftExcel} from "../Skillsheet/Skills/MicrosoftExcel";
import {MicrosoftPowerPoint} from "../Skillsheet/Skills/MicrosoftPowerPoint";
import {MicrosoftWindows} from "../Skillsheet/Skills/MicrosoftWindows";
import {MicrosoftWord} from "../Skillsheet/Skills/MicrosoftWord";
import {MinecraftServerAdministration} from "../Skillsheet/Skills/MinecraftServerAdministration";
import {MPIDevelopment} from "../Skillsheet/Skills/MPIDevelopment";
import {MyBatisORMDevelopment} from "../Skillsheet/Skills/MyBatisORMDevelopment";
import {NodeJSDevelopment} from "../Skillsheet/Skills/NodeJSDevelopment";
import {OpenCLDevelopment} from "../Skillsheet/Skills/OpenCLDevelopment";
import {OpenCVDevelopment} from "../Skillsheet/Skills/OpenCVDevelopment";
import {OpenGLDevelopment} from "../Skillsheet/Skills/OpenGL";
import {OpenMPDevelopment} from "../Skillsheet/Skills/OpenMPDevelopment";
import {OpenTKDevelopment} from "../Skillsheet/Skills/OpenTKDevelopment";
import {PHPDevelopment} from "../Skillsheet/Skills/PHPDevelopment";
import {PythonDevelopment} from "../Skillsheet/Skills/PythonDevelopment";
import {ReactJSDevelopment} from "../Skillsheet/Skills/ReactJSDevelopment";
import {SkillLevel} from "../Skillsheet/Skills/SkillLevel";
import {Spanish} from "../Skillsheet/Skills/Spanish";
import {SpigotPluginDevelopment} from "../Skillsheet/Skills/SpigotPluginDevelopment";
import {SpringDevelopment} from "../Skillsheet/Skills/SpringDevelopment";
import {SQLDevelopment} from "../Skillsheet/Skills/SQLDevelopment";
import {TomcatDevelopment} from "../Skillsheet/Skills/TomcatDevelopment";
import {UnityDevelopment} from "../Skillsheet/Skills/UnityDevelopment";
import {UnrealDevelopment} from "../Skillsheet/Skills/UnrealDevelopment";
import {VagrantDevelopment} from "../Skillsheet/Skills/VagrantDevelopment";
import {VisualStudio} from "../Skillsheet/Skills/VisualStudio";
import {VisualStudioCode} from "../Skillsheet/Skills/VisualStudioCode";
import {VueJSDevelopment} from "../Skillsheet/Skills/VueJSDevelopment";
import {WordpressAdministration} from "../Skillsheet/Skills/WordpressAdministration";
import {XNADevelopment} from "../Skillsheet/Skills/XNADevelopment";
import {CurriculumVitae} from "./CurriculumVitae";

/**
 * Represents my CV.
 */
export const QuincyBakkerCurriculumVitae = new CurriculumVitae(
	Qub1,
	[
		new Degree(
			"Master Computer Science",
			[UniversiteitVanAmsterdam, VrijeUniversiteit],
			new Date("2018-09-01"),
			new Date("2020-12-01"),
			"Parallel Computing Systems",
			[
				"Distributed Systems",
				"Evolutionary Computing",
				"Experimental Design and Data Analysis",
				"History of Digital Cultures",
				"Internet Programming",
				"Logical Verification",
				"Machine Learning for the Quantified Self",
				"Parallel Programming Practical",
				"Parallel System Architectures",
				"Performance Engineering",
				"Programming Large-Scale Parallel Systems",
				"Programming Multi-core and Many-core Systems",
				"Service Oriented Designing",
				"The Social Web"
			],
			[
				EnergyAwareRuntime,
				EnergyManager
			]
		),
		new Degree(
			"Bachelor Information Science",
			[UniversiteitUtrecht],
			new Date("2015-09-01"),
			new Date("2018-06-01"),
			"",
			[
				"Applied Games",
				"Cognition and Emotion",
				"Designing Interactive Systems",
				"E-business",
				"Humans, Society and ICT",
				"Information Exchange",
				"Information Systems",
				"Organizations and ICT",
				"Scientific Research Methods"
			],
			[Logness]
		),
		new Degree(
			"Bachelor Computer Science",
			[UniversiteitUtrecht],
			new Date("2015-09-01"),
			new Date("2018-06-01"),
			"Game Development",
			[
				"3D Modeling",
				"Artificial Intelligence",
				"Computer Architecture and Networks",
				"Concurrency",
				"Data Structures",
				"Databases",
				"Functional Programming",
				"Game Design",
				"Game Programming",
				"Game Technology Introduction Project",
				"Graphics",
				"Intelligent Systems",
				"Interaction Technology",
				"Life Sciences and Health Informatics",
				"Logic",
				"Modeling and System Development",
				"Research Methods",
				"Research Project",
				"Software Project",
				"Web Technology"
			],
			[
				MultiLink,
				SoKicking,
				Stickuino
			]
		),
		new Degree(
			"VWO Gynmansium",
			[FonsVitaeLyceum],
			new Date("2008-09-01"),
			new Date("2015-06-01"),
			"Nature and Technology (N&T)",
			[
				"Chemistry",
				"Computer Science",
				"Dutch",
				"English",
				"French",
				"Latin",
				"Mathematics (Wiskunde B)",
				"Physics"
			],
			[
				RoadMapper
			]
		)
	],
	[
		new Job(
			"C Developer",
			[SURF],
			new Date("2020-07-01"),
			new Date("2021-03-01"),
			[
				EnergyAwareRuntime,
				EnergyManager
			]
		),
		new Job(
			"Java College Developer",
			[Adyen],
			new Date("2017-05-01"),
			new Date("2019-07-01")
		),
		new Job(
			"Kotlin Back End Developer",
			[PluhZ],
			new Date("2018-02-01"),
			new Date("2018-06-01")
		),
		new Job(
			"Internship",
			[Adyen],
			new Date("2017-11-01"),
			new Date("2018-02-01"),
			[Logness]
		),
		new Job(
			"Web Developer",
			[HairstudioJoyce],
			new Date("2012-10-01"),
			new Date("2016-12-01"),
			[HairstudioJoyceProject]
		)
	],
	[
		new SkillLevel(AgileDevelopment, 3),
		new SkillLevel(AnsibleDevelopment, 3, true),
		new SkillLevel(ArduinoDevelopment, 3),
		new SkillLevel(BashDevelopment, 3),
		new SkillLevel(BoostDevelopment, 3),
		new SkillLevel(CDevelopment, 4),
		new SkillLevel(CMakeDevelopment, 4),
		new SkillLevel(CPlusPlusDevelopment, 5, true),
		new SkillLevel(CSS3Development, 5, true),
		new SkillLevel(CSharpDevelopment, 3),
		new SkillLevel(CUDADevelopment, 3),
		new SkillLevel(ChapelDevelopment, 1),
		new SkillLevel(Climbing, 2, true),
		new SkillLevel(DataTablesDevelopment, 4),
		new SkillLevel(DebianLinux, 4, true),
		new SkillLevel(DockerDevelopment, 4, true),
		new SkillLevel(DoxygenDocumentation, 4),
		new SkillLevel(Driving, 3),
		new SkillLevel(Dutch, 5),
		new SkillLevel(English, 5),
		new SkillLevel(ExpressDevelopment, 2),
		new SkillLevel(French, 2),
		new SkillLevel(GameDevelopment, 4, true),
		new SkillLevel(Gaming, 4, true),
		new SkillLevel(German, 2),
		new SkillLevel(GitDevelopment, 4),
		new SkillLevel(GitLabCIDevelopment, 4),
		new SkillLevel(GradleDevelopment, 3),
		new SkillLevel(Guitar, 3, true),
		new SkillLevel(HTML5Development, 5, true),
		new SkillLevel(JSDocDocumentation, 4),
		new SkillLevel(JavaDevelopment, 5, true),
		new SkillLevel(JavaScriptDevelopment, 5, true),
		new SkillLevel(JetbrainsSoftware, 4),
		new SkillLevel(JupyterDevelopment, 4),
		new SkillLevel(KotlinDevelopment, 3),
		new SkillLevel(KubernetesDevelopment, 2),
		new SkillLevel(LaTeXDevelopment, 3),
		new SkillLevel(LibreOffice, 3),
		new SkillLevel(MPIDevelopment, 4),
		new SkillLevel(MicrosoftExcel, 4),
		new SkillLevel(MicrosoftPowerPoint, 4),
		new SkillLevel(MicrosoftWindows, 4),
		new SkillLevel(MicrosoftWord, 4),
		new SkillLevel(MinecraftServerAdministration, 3, true),
		new SkillLevel(MyBatisORMDevelopment, 4),
		new SkillLevel(NodeJSDevelopment, 4),
		new SkillLevel(OpenCLDevelopment, 3),
		new SkillLevel(OpenCVDevelopment, 2),
		new SkillLevel(OpenGLDevelopment, 3),
		new SkillLevel(OpenMPDevelopment, 3),
		new SkillLevel(OpenTKDevelopment, 1),
		new SkillLevel(PHPDevelopment, 3),
		new SkillLevel(PythonDevelopment, 5, true),
		new SkillLevel(ReactJSDevelopment, 4),
		new SkillLevel(SQLDevelopment, 3),
		new SkillLevel(Spanish, 2),
		new SkillLevel(SpigotPluginDevelopment, 3, true),
		new SkillLevel(SpringDevelopment, 3),
		new SkillLevel(TomcatDevelopment, 2),
		new SkillLevel(UnityDevelopment, 3),
		new SkillLevel(UnrealDevelopment, 1),
		new SkillLevel(VagrantDevelopment, 3),
		new SkillLevel(VisualStudio, 3),
		new SkillLevel(VisualStudioCode, 4),
		new SkillLevel(VueJSDevelopment, 3),
		new SkillLevel(WordpressAdministration, 2),
		new SkillLevel(XNADevelopment, 3)
	],
	[
		EnergyAwareRuntime,
		EnergyManager,
		GrapplingOptions,
		HairstudioJoyceProject,
		LiquidCraft,
		Logness,
		MineQraft,
		MultiLink,
		QuLib,
		Qub1com,
		RoadMapper,
		SoKicking,
		SortPlusPlus,
		Spaceshow,
		Stickuino,
		Vengine,
		WolfPackageManager
	]
);
