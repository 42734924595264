import {Component} from "../Layout/Component";

/**
 * The outer wrapper.
 */
const Wrapper = Component.Section`
	background: white;
	box-shadow: 0.5em 0.5em rgba(0, 0, 0, 0.5);
	box-sizing: border-box;
	display: block;
	height: 297mm;
	padding: 0.3in 0.5in;
	width: 210mm;
`;

/**
 * Wraps the content.
 */
const ContentWrapper = Component.Div`
	height: 100%;
	width: 100%;
`;

/**
 * Contains text and other content.
 */
export class Page extends Component {
	_onRender() {
		return (
			<Wrapper className={this.props.className} ref={this.properties.reference} id="printPage">
				<ContentWrapper>
					{this.children}
				</ContentWrapper>
			</Wrapper>
		);
	}
}

Component.DefaultProperties(Page, {
	/**
	 * The reference to bind to the outer element.
	 */
	reference: null
});
