import "@fontsource/open-sans";
import "@fontsource/oswald";
import "@fontsource/vt323";
import {faCode, faStar} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {darken, lighten, mix} from "polished";
import {ContactCard} from "../../Communication/Contacts/ContactCard";
import {Component} from "../../Layout/Component";
import {Document} from "../Document";
import {Skillsheet} from "../Skillsheet/Skillsheet";
import {SkillsOverview} from "../Skillsheet/SkillsOverview";
import "./CurriculumVitae";

/**
 * The document title.
 */
const Title = Component.H1`
	font-family: Oswald, sans-serif;
	font-size: 2em;
	text-align: center;
`;

/**
 * A section.
 */
const Section = Component.Section`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
`;

const TitleSection = Component.Section`
	display: flex;
	flex-direction: row;
	gap: 0.25em;
	justify-content: space-evenly;

	${Section}:first-child {
		width: 55%;
	}

	${Section}:last-child {
		width: 40%;
	}
`;

/**
 * The title of a section.
 */
const SectionTitle = Component.H2`
	font-family: Oswald, sans-serif;
	font-size: 1.5em;
	margin-top: 1em;
	white-space: nowrap;
`;

/**
 * A section paragraph.
 */
const Paragraph = Component.P`
	margin-bottom: 1em;
	font-family: "Open Sans", sans-serif;
`;

/**
 * A two column layout.
 */
const TwoColumnLayout = Component.Section`
	display: flex;
	flex-direction: row;
	gap: 1em;
	justify-content: space-evenly;
	flex-wrap: wrap;
	width: 100%;

	> * {
		width: 49%;
		display: flex;
		flex-direction: column;
		gap: 1em;
		align-items: center;
		justify-content: space-evenly;
	}
`;

/**
 * A three column layout.
 */
const ThreeColumnLayout = Component.Section`
	display: flex;
	flex-direction: row;
	gap: 0.5em;
	justify-content: space-evenly;
	width: 100%;

	> * {
		width: 32%;
		display: flex;
		flex-direction: column;
		gap: 0.5em;
		align-items: center;
		justify-content: flex-start;
	}
`;

/**
 * A table in the document.
 */
const Table = Component.Table`
	border-spacing: 0;
	width: 100%;
	max-height: 100%;

	thead {
		th {
			text-align: left;
			border-bottom: 0.01em solid black;
		}
	}

	tbody {
		tr {
			:nth-child(odd) {
				background: rgb(0, 0, 0, 0.05);
			}

			:nth-child(even) {
				background: rgb(0, 0, 0, 0.1);
			}
		}
	}

	tr {
		td, th {
			padding: 0.2em 0.4em;
			vertical-align: top;
		}
	}
`;

/**
 * A table showing some timespans.
 */
const TimespanTable = Component.Wrapper(Table)`
	tr {
		td:first-child, td:nth-child(2), th:first-child, th:nth-child(2) {
			text-align: center;
			width: 5em;
		}

		td:nth-child(3), th:nth-child(3) {
			text-align: right;
			width: 10em;
		}
	}
`;

/**
 * A table showing item descriptions.
 */
const DescriptionTable = Component.Wrapper(Table)`
	tr {
		td:first-child, th:first-child {
			text-align: right;
			width: 5em;
		}
	}
`;

/**
 * A table showing skills.
 */
const SkillTable = Component.Wrapper(Table)`
	tr {
		font-size: 0.9em;
		td {
			vertical-align: middle;
		}
		td:first-child {
			text-align: center;
		}
		td:nth-child(2), th:nth-child(2) {
			width: 100%;
		}
		td:nth-child(3), th:nth-child(2) {
			text-align: center;
		}
	}
`;

/**
 * A small Skillsheet.
 */
const SmallSkillsheet = Component.Wrapper(Skillsheet)`
	font-size: 0.53em;
	width: 110%;
	height: 800px;
	margin-left: -5%;
`;

/**
 * A skill level.
 */
const Level = Component.Span`
	background: ${props => mix(props.level / 5.0, "blueviolet", "cyan")};
	border-radius: 2em;
	border: 1px solid black;
	color: white;
	text-shadow: 1px 1px 0 #000;
	display: inline-block;
	font-weight: bold;
	height: 1.5em;
	padding: 0.1em 0.5em;
	margin-right: 0.5em;
	text-align: center;
	font-size: 0.75em;
	white-space: nowrap;
	min-width: 5em;
`;

const HobbyIcon = Component.Span`
	color: rgb(160, 120, 25);
	font-size: 0.75em;
	position: relative;
	top: -0.5em;
	left: 0.2em;
`;

const backgroundColor = lighten(0.05, "rgb(0, 28, 122)");

const ProjectCard = Component.Div`
	background-color: #eee;
	border-radius: 1em;
	font-size: 0.9em;
	overflow: hidden;
	display: flex;
	flex-direction: row;
	align-items: stretch;
	justify-content: center;
`;

const NoImageIcon = Component.Wrapper(FontAwesomeIcon)`
	color: gray;
	font-size: 5em;
	margin: 0.4em;
`;

const ProjectImage = Component.Div`
	align-items: center;
	background: rgba(0, 0, 0, 0.2);
	box-sizing: border-box;
	display: flex;
	flex-direction: column;
	justify-content: center;
	width: 10em;
	overflow: hidden;

	img {
		height: 100%;
		object-fit: cover;
		width: 100%;
	}
`;

const ProjectText = Component.Div`
	padding: 0.5em 1em;
	width: calc(100% - 10em);
`;

/**
 * The contact card.
 */
const WideContactCard = Component.Wrapper(ContactCard)`
	background: linear-gradient(to right, ${darken(0.15, backgroundColor)} 0%, ${darken(0.1, backgroundColor)} 2em, ${backgroundColor} 50%, ${darken(0.1, backgroundColor)} calc(100% - 2em), ${darken(0.15, backgroundColor)} 100%);
	box-shadow: 0.4em 0.4em rgba(0, 0, 0, 0.8);
	margin-top: 0.5em;
	margin: 0 auto;
	max-width: 25em;
	padding: 1em 1.5em;
	border-radius: 1em;
	transform: scale(1.2);
`;

const ExtraSection = Component.Wrapper(Section)`
	margin-top: 5em;
	align-items: center;
	font-weight: bold;
`;

/**
 * Outlines a person's skills, interests and professional experience as a document.
 */
export const CurriculumVitaeDocument = Component.Translate(class CurriculumVitaeDocument extends Component {
	_onRender() {
		let that = this;

		function getLevelString(level) {
			switch (level) {
				case 1:
					return that.translate("Documents.CurriculumVitae.Novice");
				case 2:
					return that.translate("Documents.CurriculumVitae.Familiar");
				case 3:
					return that.translate("Documents.CurriculumVitae.Experienced");
				case 4:
					return that.translate("Documents.CurriculumVitae.Professional");
				case 5:
					return that.translate("Documents.CurriculumVitae.Expert");
			}
		}

		// Sort the skills by their level and then their name so the best skills are on top
		const sortedSkillLevels = this.properties.curriculumVitae.skillLevels.sort((left, right) => left.level === right.level ? (
			left.skill.name > right.skill.name ? 1 : -1
		) : (
			left.level < right.level ? 1 : -1
		));

		const createSkillLevelTableColumn = skillLevels => {
			return (
				<div>
					<SkillTable>
						<thead>
							<tr>
								<th colSpan="2">{this.translate("Documents.CurriculumVitae.Skill")}</th>
								<th>{this.translate("Documents.CurriculumVitae.Level")}</th>
							</tr>
						</thead>
						<tbody>
							{skillLevels.map((skillLevel, index) => (
								<tr key={index}>
									<td>{skillLevel.skill.icon}</td>
									<td>{skillLevel.skill.name}<HobbyIcon>{skillLevel.isHobby && <FontAwesomeIcon icon={faStar}/>}</HobbyIcon></td>
									<td><Level level={skillLevel.level}>{skillLevel.level} - {getLevelString(skillLevel.level)}</Level></td>
								</tr>
							))}
						</tbody>
					</SkillTable>
				</div>
			);
		};

		const createSkillLevelTable = skillLevels => {
			let chunkSize = skillLevels.length / 3;

			return (
				<Section>
					<ThreeColumnLayout>
						{createSkillLevelTableColumn(skillLevels.slice(0, chunkSize))}
						{createSkillLevelTableColumn(skillLevels.slice(chunkSize, chunkSize * 2))}
						{createSkillLevelTableColumn(skillLevels.slice(chunkSize * 2, skillLevels.length))}
					</ThreeColumnLayout>
				</Section>
			);
		};

		const createProjectsTableColumn = projects => {
			return (
				<div>
					{projects.map((project, index) => (
						<ProjectCard key={index}>
							<ProjectImage>
								{project.multimedia.length > 0 ? (
									<img src={project.multimedia[0]} alt="Image showcasing the project"/>
								) : (
									<NoImageIcon icon={faCode}/>
								)}
							</ProjectImage>
							<ProjectText>
								<h2>{project.name}</h2>
								{project.description}<br/>
								<strong>{this.translate("Documents.Articles.Projects.ProjectGallery.Role")}:</strong> {project.roleDescription}
								<SkillsOverview skills={project.skills}/>
							</ProjectText>
						</ProjectCard>
					))}
				</div>
			);
		};

		const createProjectsTable = projects => {
			let chunkSize = projects.length / 2;

			return (
				<Section>
					<TwoColumnLayout>
						{createProjectsTableColumn(projects.slice(0, chunkSize))}
						{createProjectsTableColumn(projects.slice(chunkSize, projects.length))}
					</TwoColumnLayout>
				</Section>
			);
		};

		return (
			<Document>
				<Title>{this.translate("documents.curriculumVitae.title")}</Title>
				<TitleSection>
					<Section>
						<SectionTitle>{this.translate("documents.curriculumVitae.biography")}</SectionTitle>
						<Paragraph>{this.properties.curriculumVitae.contact.biography}</Paragraph>
					</Section>
					<Section>
						<WideContactCard contact={this.properties.curriculumVitae.contact}/>
					</Section>
				</TitleSection>
				<Section>
					<SectionTitle>{this.translate("documents.curriculumVitae.degrees")}</SectionTitle>
					<TimespanTable>
						<thead>
							<tr>
								<th>{this.translate("Documents.CurriculumVitae.TimespanStart")}</th>
								<th>{this.translate("Documents.CurriculumVitae.TimespanEnd")}</th>
								<th>{this.translate("Documents.CurriculumVitae.Institutions")}</th>
								<th>{this.translate("Documents.CurriculumVitae.Degree")}</th>
							</tr>
						</thead>
						<tbody>
							{this.properties.curriculumVitae.degrees.map((degree, index) => (
								<tr key={index}>
									<td>{degree.startDate.getMonth() + 1} - {degree.startDate.getFullYear()}</td>
									<td>{degree.endDate.getMonth() + 1} - {degree.endDate.getFullYear()}</td>
									<td>{degree.institutions.map((institution, index) => (
										<div key={index}>
											{institution.name}
										</div>
									))}</td>
									<td>
										{degree.name}
										{degree.minor !== "" && (
											<>
												<br/>
												<i>Minor:</i> {degree.minor}
											</>
										)}
									</td>
								</tr>
							))}
						</tbody>
					</TimespanTable>
				</Section>
				<Section>
					<SectionTitle>{this.translate("documents.curriculumVitae.professionalExperience")}</SectionTitle>
					<TimespanTable>
						<thead>
							<tr>
								<th>{this.translate("Documents.CurriculumVitae.TimespanStart")}</th>
								<th>{this.translate("Documents.CurriculumVitae.TimespanEnd")}</th>
								<th>{this.translate("Documents.CurriculumVitae.Companies")}</th>
								<th>{this.translate("Documents.CurriculumVitae.Position")}</th>
							</tr>
						</thead>
						<tbody>
							{this.properties.curriculumVitae.jobs.map((job, index) => (
								<tr key={index}>
									<td>{job.startDate.getMonth() + 1} - {job.startDate.getFullYear()}</td>
									<td>{job.endDate.getMonth() + 1} - {job.endDate.getFullYear()}</td>
									<td>{job.companies.map((company, index) => (
										<div key={index}>
											{company.name}
										</div>
									))}</td>
									<td>{job.name}</td>
								</tr>
							))}
						</tbody>
					</TimespanTable>
				</Section>
				<Section>
					<SectionTitle>{this.translate("documents.curriculumVitae.skills")}</SectionTitle>
					{this.translate("Documents.CurriculumVitae.SkillsExplanation")}
				</Section>
				{createSkillLevelTable(sortedSkillLevels.slice(0, 45))}
				{createSkillLevelTable(sortedSkillLevels.slice(45, 999))}
				<Section>
					<SectionTitle>{this.translate("documents.curriculumVitae.projects")}</SectionTitle>
				</Section>
				{createProjectsTable(this.properties.curriculumVitae.projects.slice(0, 8))}
				{createProjectsTable(this.properties.curriculumVitae.projects.slice(8, 999))}
				<ExtraSection>
					{this.translate("Documents.CurriculumVitae.CardExtra")}
				</ExtraSection>
			</Document>
		);
	}
}, [
	{
		language: "en",
		translations: {
			Documents: {
				CurriculumVitae: {
					TimespanStart: `Start`,
					TimespanEnd: `End`,
					Institutions: `Institutions`,
					Companies: `Companies`,
					Degree: `Degree`,
					Position: `Position`,
					Name: `Name`,
					Description: `Description`,
					Skill: `Skill`,
					Level: `Level`,
					CardExtra: `For more details, visit my website at www.qub1.com/CurriculumVitae`,
					SkillsExplanation: `Skills marked with a star are also hobbies that I enjoy in my free time.`,
					Novice: `Novice`,
					Familiar: `Familiar`,
					Experienced: `Experienced`,
					Professional: `Pro`,
					Expert: `Expert`
				}
			}
		}
	}, {
		language: "nl",
		translations: {
			Documents: {
				CurriculumVitae: {
					TimespanStart: `Start`,
					TimespanEnd: `Eind`,
					Institutions: `Instellingen`,
					Companies: `Bedrijven`,
					Degree: `Diploma`,
					Position: `Positie`,
					Name: `Naam`,
					Description: `Beschrijving`,
					Skill: `Vaardigheid`,
					Level: `Niveau`,
					CardExtra: `Voor meer informatie, bezoek mijn website op www.qub1.com/CurriculumVitae`,
					SkillsExplanation: `Vaardigheden die zijn gemarkeerd met een ster zijn hobbies die ik graag uitoefen in mijn vrije tijd.`,
					Novice: `Beginner`,
					Familiar: `Bekend`,
					Experienced: `Ervaren`,
					Professional: `Pro`,
					Expert: `Expert`
				}
			}
		}
	}
]);
