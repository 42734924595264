import {i18next} from "../../../../i18next";
import {AnsibleDevelopment} from "../../../Skillsheet/Skills/AnsibleDevelopment";
import {BashDevelopment} from "../../../Skillsheet/Skills/BashDevelopment";
import {DebianLinux} from "../../../Skillsheet/Skills/DebianLinux";
import {DockerDevelopment} from "../../../Skillsheet/Skills/DockerDevelopment";
import {Dutch} from "../../../Skillsheet/Skills/Dutch";
import {English} from "../../../Skillsheet/Skills/English";
import {Gaming} from "../../../Skillsheet/Skills/Gaming";
import {GitDevelopment} from "../../../Skillsheet/Skills/GitDevelopment";
import {GitLabCIDevelopment} from "../../../Skillsheet/Skills/GitLabCIDevelopment";
import {JavaDevelopment} from "../../../Skillsheet/Skills/JavaDevelopment";
import {MinecraftServerAdministration} from "../../../Skillsheet/Skills/MinecraftServerAdministration";
import {SpigotPluginDevelopment} from "../../../Skillsheet/Skills/SpigotPluginDevelopment";
import {WordpressAdministration} from "../../../Skillsheet/Skills/WordpressAdministration";
import {Project} from "../Project";
import MineQraftImage from "./MineQraft.png";

i18next
	.addResourceBundle("en", "translation", {
		Documents: {
			Articles: {
				Projects: {
					MineQraft: {
						Description: `MineQraft is a Minecraft server network.`,
						RoleDescription: `I host and administrate the network.`
					}
				}
			}
		}
	}, true)
	.addResourceBundle("nl", "translation", {
		Documents: {
			Articles: {
				Projects: {
					MineQraft: {
						Description: `MineQraft is een Minecraft server netwerk.`,
						RoleDescription: `Ik host en beheer het netwerk.`
					}
				}
			}
		}
	}, true);

export const MineQraft = new Project("MineQraft", null, null, new URL("https://mineqraft.qub1.com"), [
	AnsibleDevelopment,
	BashDevelopment,
	DebianLinux,
	DockerDevelopment,
	Dutch,
	English,
	Gaming,
	GitDevelopment,
	GitLabCIDevelopment,
	JavaDevelopment,
	MinecraftServerAdministration,
	SpigotPluginDevelopment,
	WordpressAdministration
], [MineQraftImage]);

function update() {
	MineQraft.description = i18next.t("Documents.Articles.Projects.MineQraft.Description");
	MineQraft.roleDescription = i18next.t("Documents.Articles.Projects.MineQraft.RoleDescription");
}

i18next.on("languageChanged", language => {
	update();
});

update();
