import {i18next} from "../../../i18next";
import {CPlusPlusDevelopment} from "../../Skillsheet/Skills/CPlusPlusDevelopment";
import {DoxygenDocumentation} from "../../Skillsheet/Skills/DoxygenDocumentation";
import {English} from "../../Skillsheet/Skills/English";
import {GitDevelopment} from "../../Skillsheet/Skills/GitDevelopment";
import {GitLabCIDevelopment} from "../../Skillsheet/Skills/GitLabCIDevelopment";
import {PythonDevelopment} from "../../Skillsheet/Skills/PythonDevelopment";
import {Project} from "./Project";

i18next
	.addResourceBundle("en", "translation", {
		Documents: {
			Articles: {
				Projects: {
					WolfPackageManager: {
						Description: `Wolf Package Manager is a set of tools that assist the user with building, packaging and uploading projects to various package registries such as Conan and PyPI.`,
						RoleDescription: `I designed and wrote the tools.`
					}
				}
			}
		}
	}, true)
	.addResourceBundle("nl", "translation", {
		Documents: {
			Articles: {
				Projects: {
					WolfPackageManager: {
						Description: `Wolf Package Manager is een set hulpmiddelen die helpen bij het bouwen, verpakken en uploaden van projected naar verscheidene package registries zoals Conan en PyPI.`,
						RoleDescription: `Ik heb de tools ontworpen en geschreven.`
					}
				}
			}
		}
	}, true);

export const WolfPackageManager = new Project("Wolf Package Manager", null, null, new URL("https://gitlab.qub1.com/QuLib/QuLib.Deployment"), [
	CPlusPlusDevelopment,
	DoxygenDocumentation,
	English,
	GitDevelopment,
	GitLabCIDevelopment,
	PythonDevelopment
]);

function update() {
	WolfPackageManager.description = i18next.t("Documents.Articles.Projects.WolfPackageManager.Description");
	WolfPackageManager.roleDescription = i18next.t("Documents.Articles.Projects.WolfPackageManager.RoleDescription");
}

i18next.on("languageChanged", language => {
	update();
});

update();
