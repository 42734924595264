import {darken} from "polished";
import {Component} from "./Component";

const backgroundColor = "rgb(0, 28, 122)";

const Wrapper = Component.Section`
	align-items: center;
	background: linear-gradient(to right, ${darken(0.15, backgroundColor)} 0%, ${darken(0.1, backgroundColor)} 2em, ${backgroundColor} 50%, ${darken(0.1, backgroundColor)} calc(100% - 2em), ${darken(0.15, backgroundColor)} 100%);
	color: white;
	display: flex;
	flex-direction: column;
	font-family: "Open Sans", sans-serif;
	gap: 1em;
	justify-content: center;
	min-height: 100vh;
	position: relative;
	z-index: 1;
	box-shadow: 0 4em 2em 4em black;
`;

/**
 * The center section, where the view starts.
 */
export class CenterSection extends Component {
	_onRender() {
		return (
			<Wrapper>
				{this.children}
			</Wrapper>
		);
	}
}
