/**
 * Represents a Job at a Company.
 */
export class Job {
	/**
	 * Creates a new Job.
	 * @param {string} name The name.
	 * @param {Company[]} companies The companies.
	 * @param {Date} startDate The date the Job was started.
	 * @param {Date} endDate The date the Job was finished/obtained.
	 * @param {Project[]} projects The Projects that relate to the Job.
	 */
	constructor(name, companies, startDate, endDate, projects = []) {
		/**
		 * The name.
		 * @type {string}
		 */
		this.name = name;

		/**
		 * The companies.
		 * @type {Company[]}
		 */
		this.companies = companies;

		/**
		 * The date the Job was started.
		 * @type {Date}
		 */
		this.startDate = startDate;

		/**
		 * The date the Job was finished/obtained.
		 * @type {Date}
		 */
		this.endDate = endDate;

		/**
		 * The Projects that relate to the Job.
		 * @type {Project[]}
		 */
		this.projects = projects;
	}
}
