import {faCode} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {lighten} from "polished";
import randomColor from "randomcolor";
import {Link} from "react-router-dom";
import {Component} from "../../../Layout/Component";
import {Gallery} from "../../../Multimedia/Gallery";
import {SkillsOverview} from "../../Skillsheet/SkillsOverview";

/**
 * A small Skills overview.
 */
const SmallSkillsOverview = Component.Wrapper(SkillsOverview)`
	font-size: 0.8em;
`;

/**
 * A Project.
 */
const Project = Component.Wrapper(Link)`
	color: white !important;
	display: flex;
	flex-direction: row;
	gap: 1.5em;
	
	@media (max-width: 500px) {
		flex-direction: column;
	}
`;

/**
 * A column.
 */
const ProjectColumn = Component.Div`
	align-items: center;
	display: flex;
	flex-direction: column;
	gap: 0.4em;
	justify-content: center;
`;

/**
 * The image column.
 */
const ImageColumn = Component.Wrapper(ProjectColumn)`
`;

const Image = Component.Div`
	align-items: center;
	background: rgba(0, 0, 0, 0.2);
	box-sizing: border-box;
	display: flex;
	flex-direction: column;
	height: 100%;
	justify-content: center;
	max-height: 12.5em;
	width: 12.5em;

	@media (max-width: 500px) {
		max-height: 10em;
		width: 100%;
	}

	img {
		height: 100%;
		object-fit: cover;
		width: 100%;
	}
`;

const NoImageIcon = Component.Wrapper(FontAwesomeIcon)`
	color: gray;
	font-size: 5em;
	margin: 0.4em;
`;

const InformationColumn = Component.Wrapper(ProjectColumn)`
	align-items: start;
	flex: 1;
`;

/**
 * The name of a Project.
 */
const ProjectName = Component.H3`
`;

/**
 * My personal portfolio.
 */
export const ProjectGallery = Component.Translate(class ProjectGallery extends Component {
	_onRender() {
		return (
			<Gallery>
				{this.properties.projects
					.map((project, index) => (
						<Project key={index} to={"/Project/" + project.name.replaceAll(/\s/g, "")}>
							<ImageColumn>
								<Image>
									{project.multimedia.length > 0 ? (
										<img src={project.multimedia[0]} alt="Image showcasing the project"/>
									) : (
										<NoImageIcon icon={faCode}/>
									)}
								</Image>
							</ImageColumn>
							<InformationColumn>
								<ProjectName style={{
									color: lighten(0., randomColor({
										seed: project.name.toLowerCase(),
										luminosity: "bright",
										format: "hex"
									}))
								}}>{project.name}</ProjectName>
								{project.description.length <= this.properties.maximumDescriptionLength ? project.description : project.description.substring(0, this.properties.maximumDescriptionLength) + "..."}
								<span>
									<strong>{this.translate("Documents.Articles.Projects.ProjectGallery.Role")}:</strong> {project.roleDescription}
								</span>
								<SmallSkillsOverview skills={project.skills}/>
							</InformationColumn>
						</Project>
					))}
			</Gallery>
		);
	}
}, [
	{
		language: "en",
		translations: {
			Documents: {
				Articles: {
					Projects: {
						ProjectGallery: {
							Role: `Role`
						}
					}
				}
			}
		}
	}, {
		language: "nl",
		translations: {
			Documents: {
				Articles: {
					Projects: {
						ProjectGallery: {
							Role: `Rol`
						}
					}
				}
			}
		}
	}
]);

Component.DefaultProperties(ProjectGallery, {
	/**
	 * The length at which to truncate descriptions.
	 * @type {number}
	 */
	maximumDescriptionLength: 200,

	/**
	 * The projects to display.
	 * @type {Project[]}
	 */
	projects: []
});
