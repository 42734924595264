import {i18next} from "../../../../i18next";
import {AnsibleDevelopment} from "../../../Skillsheet/Skills/AnsibleDevelopment";
import {BashDevelopment} from "../../../Skillsheet/Skills/BashDevelopment";
import {CSS3Development} from "../../../Skillsheet/Skills/CSS3Development";
import {DebianLinux} from "../../../Skillsheet/Skills/DebianLinux";
import {DockerDevelopment} from "../../../Skillsheet/Skills/DockerDevelopment";
import {Dutch} from "../../../Skillsheet/Skills/Dutch";
import {English} from "../../../Skillsheet/Skills/English";
import {GitDevelopment} from "../../../Skillsheet/Skills/GitDevelopment";
import {GitLabCIDevelopment} from "../../../Skillsheet/Skills/GitLabCIDevelopment";
import {HTML5Development} from "../../../Skillsheet/Skills/HTML5Development";
import {JavaScriptDevelopment} from "../../../Skillsheet/Skills/JavaScriptDevelopment";
import {JSDocDocumentation} from "../../../Skillsheet/Skills/JSDocDocumentation";
import {NodeJSDevelopment} from "../../../Skillsheet/Skills/NodeJSDevelopment";
import {ReactJSDevelopment} from "../../../Skillsheet/Skills/ReactJSDevelopment";
import {VagrantDevelopment} from "../../../Skillsheet/Skills/VagrantDevelopment";
import {Project} from "../Project";
import Qub1comImage from "./Qub1.com.png";
import Qub1comImage2 from "./Qub1.com2.png";
import Qub1comImage3 from "./Qub1.com3.png";

i18next
	.addResourceBundle("en", "translation", {
		Documents: {
			Articles: {
				Projects: {
					Qub1com: {
						Description: `
							Qub1.com is my own server, providing access to various services that run locally on a small cluster I manage.
							These services include source control, game servers, multimedia servers and more.
						`,
						RoleDescription: `I host and manage the server.`
					}
				}
			}
		}
	}, true)
	.addResourceBundle("nl", "translation", {
		Documents: {
			Articles: {
				Projects: {
					Qub1com: {
						Description: `
							Qub1.com is mijn eigen server, en deze server geeft toegang tot een aantal services die lokaal draaien in een klein cluster dat ik beheer.
							Onder deze services vallen versiebeheer, game servers, multimedia servers en meer.
						`,
						RoleDescription: `Ik host en beheer de server.`
					}
				}
			}
		}
	}, true);

export const Qub1com = new Project("Qub1.com", null, null, new URL("https://qub1.com"), [
	AnsibleDevelopment,
	BashDevelopment,
	CSS3Development,
	DebianLinux,
	DockerDevelopment,
	Dutch,
	English,
	GitDevelopment,
	GitLabCIDevelopment,
	HTML5Development,
	JSDocDocumentation,
	JavaScriptDevelopment,
	NodeJSDevelopment,
	ReactJSDevelopment,
	VagrantDevelopment
], [
	Qub1comImage,
	Qub1comImage2,
	Qub1comImage3
]);

function update() {
	Qub1com.description = i18next.t("Documents.Articles.Projects.Qub1com.Description");
	Qub1com.roleDescription = i18next.t("Documents.Articles.Projects.Qub1com.RoleDescription");
}

i18next.on("languageChanged", language => {
	update();
});

update();
