import {i18next} from "../../../../i18next";
import {CSS3Development} from "../../../Skillsheet/Skills/CSS3Development";
import {DataTablesDevelopment} from "../../../Skillsheet/Skills/DataTablesDevelopment";
import {English} from "../../../Skillsheet/Skills/English";
import {GitDevelopment} from "../../../Skillsheet/Skills/GitDevelopment";
import {GradleDevelopment} from "../../../Skillsheet/Skills/GradleDevelopment";
import {HTML5Development} from "../../../Skillsheet/Skills/HTML5Development";
import {JavaDevelopment} from "../../../Skillsheet/Skills/JavaDevelopment";
import {JavaScriptDevelopment} from "../../../Skillsheet/Skills/JavaScriptDevelopment";
import {JSDocDocumentation} from "../../../Skillsheet/Skills/JSDocDocumentation";
import {LaTeXDevelopment} from "../../../Skillsheet/Skills/LaTeXDevelopment";
import {MyBatisORMDevelopment} from "../../../Skillsheet/Skills/MyBatisORMDevelopment";
import {SQLDevelopment} from "../../../Skillsheet/Skills/SQLDevelopment";
import {TomcatDevelopment} from "../../../Skillsheet/Skills/TomcatDevelopment";
import {VueJSDevelopment} from "../../../Skillsheet/Skills/VueJSDevelopment";
import {Project} from "../Project";
import ChatIntegration from "./ChatIntegration.png";
import MainInterface from "./MainInterface.png";
import MainInterface2 from "./MainInterface2.png";

i18next
	.addResourceBundle("en", "translation", {
		Documents: {
			Articles: {
				Projects: {
					Logness: {
						Description: `
							Logness is an application used to track log records and flag suspicious log entries based on their severity.
							Users can then check the flagged logs to see if there are any issues.
						`,
						RoleDescription: `
							I took the existing application and modified the user interface to be reactive and gamified, so that users see a dashboard of events.
							I also improved the logging shown in the internal chat system.
						`
					}
				}
			}
		}
	}, true)
	.addResourceBundle("nl", "translation", {
		Documents: {
			Articles: {
				Projects: {
					Logness: {
						Description: `
							Logness is een applicatie die word gebruikt om log gegevens door te zoeken en verdachte logs te markeren gebaseerd op hun niveau.
							Gebruikers kunnen dan vervolgens de gemarkeerde logs bekijken om te zien of er problemen zijn.
						`,
						RoleDescription: `
							Ik heb de bestaande applicatie gemodificeerd en het gebruikersinterface reactive en gamified gemaakt, zodat gebruikers een dashboard met evenementen kunnen zien.
							Ook heb ik het loggen naar het interne chat systeem verbeterd.
						`
					}
				}
			}
		}
	}, true);

export const Logness = new Project("Logness", null, null, new URL("https://qub1.com/Project/Logness"), [
	CSS3Development,
	DataTablesDevelopment,
	English,
	GitDevelopment,
	GradleDevelopment,
	HTML5Development,
	JSDocDocumentation,
	JavaDevelopment,
	JavaScriptDevelopment,
	LaTeXDevelopment,
	MyBatisORMDevelopment,
	SQLDevelopment,
	TomcatDevelopment,
	VueJSDevelopment
], [
	MainInterface,
	MainInterface2,
	ChatIntegration
]);

function update() {
	Logness.description = i18next.t("Documents.Articles.Projects.Logness.Description");
	Logness.roleDescription = i18next.t("Documents.Articles.Projects.Logness.RoleDescription");
}

i18next.on("languageChanged", language => {
	update();
});

update();
