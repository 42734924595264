import {faHtml5} from "@fortawesome/free-brands-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {i18next} from "../../../i18next";
import {Skill} from "./Skill";

i18next
	.addResourceBundle("en", "translation", {
		Documentation: {
			Skillsheet: {
				Skills: {
					HTML5Development: `HTML5 Development`
				}
			}
		}
	}, true)
	.addResourceBundle("nl", "translation", {
		Documentation: {
			Skillsheet: {
				Skills: {
					HTML5Development: `HTML5 Ontwikkeling`
				}
			}
		}
	}, true);

export const HTML5Development = new Skill(null, <FontAwesomeIcon icon={faHtml5}/>);

function update() {
	HTML5Development.name = i18next.t("Documentation.Skillsheet.Skills.HTML5Development");
}

i18next.on("languageChanged", language => {
	update();
});

update();
