import {Contact} from "@qub1/qulibjs";
import {Degree} from "../../Education/Degree";
import {i18next} from "../../i18next";
import {Job} from "../../Work/Job";
import {SkillLevel} from "../Skillsheet/Skills/SkillLevel";

i18next
	.addResourceBundle("en", "translation", {
		documents: {
			curriculumVitae: {
				title: `Curriculum Vitae`,
				biography: `Biography`,
				degrees: `Degrees`,
				professionalExperience: `Professional Experience`,
				skills: `Skills`,
				projects: `Portfolio`,
				hobbies: `Hobbies`
			}
		}
	}, true)
	.addResourceBundle("nl", "translation", {
		documents: {
			curriculumVitae: {
				title: `Curriculum Vitae`,
				biography: `Biografie`,
				degrees: `Diplomas`,
				professionalExperience: `Professionele Ervaring`,
				skills: `Vaardigheden`,
				projects: `Portfolio`,
				hobbies: `Hobby's`
			}
		}
	}, true);

/**
 * Outlines a person's skills, interests and professional experience.
 */
export class CurriculumVitae {
	/**
	 * Creates a new CurriculumVitae.
	 * @param {Contact} contact The contact that is the subject of the CV.
	 * @param {Degree[]} degrees The degrees that have been obtained.
	 * @param {Job[]} jobs The jobs that have been worked.
	 * @param {SkillLevel[]} skillLevels The skillLevels.
	 * @param {Project[]} projects The projects.
	 */
	constructor(contact, degrees = [], jobs = [], skillLevels = [], projects = []) {
		/**
		 * The Contact that is the subject of the CV.
		 * @type {Contact}
		 */
		this.contact = contact;

		/**
		 * The Degrees that have been obtained.
		 * @type {Degree[]}
		 */
		this.degrees = degrees;

		/**
		 * The Jobs that have been worked.
		 * @type {Job[]}
		 */
		this.jobs = jobs;

		/**
		 * The Skill levels.
		 * @type {SkillLevel[]}
		 */
		this.skillLevels = skillLevels;

		/**
		 * The Projects.
		 * @type {Project[]}
		 */
		this.projects = projects;
	}
}
