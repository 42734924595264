import {i18next} from "../../../i18next";
import {English} from "../../Skillsheet/Skills/English";
import {GameDevelopment} from "../../Skillsheet/Skills/GameDevelopment";
import {JavaDevelopment} from "../../Skillsheet/Skills/JavaDevelopment";
import {SpigotPluginDevelopment} from "../../Skillsheet/Skills/SpigotPluginDevelopment";
import {Project} from "./Project";

i18next
	.addResourceBundle("en", "translation", {
		Documents: {
			Articles: {
				Projects: {
					LiquidCraft: {
						Description: `LiquidCraft is a cellular-automata-based liquid simulation plugin for Minecraft that simulates pressure as well as accurate liquid levels.`,
						RoleDescription: `I designed and wrote the plugin.`
					}
				}
			}
		}
	}, true)
	.addResourceBundle("nl", "translation", {
		Documents: {
			Articles: {
				Projects: {
					LiquidCraft: {
						Description: `LiquidCraft is een cellulaire-automata-gebaseerde vloeistoffen simulatie plugin voor Minecraft die druk en nauwkeurige vloeistofniveaus simuleert.`,
						RoleDescription: `Ik heb de plugin ontworpen en geschreven.`
					}
				}
			}
		}
	}, true);

export const LiquidCraft = new Project("LiquidCraft", null, null, new URL("https://github.com/Qub1/LiquidCraft"), [
	English,
	GameDevelopment,
	JavaDevelopment,
	SpigotPluginDevelopment
]);

function update() {
	LiquidCraft.description = i18next.t("Documents.Articles.Projects.LiquidCraft.Description");
	LiquidCraft.roleDescription = i18next.t("Documents.Articles.Projects.LiquidCraft.RoleDescription");
}

i18next.on("languageChanged", language => {
	update();
});

update();
