import {Component} from "../../Layout/Component";
import {Content} from "../../Layout/Content";
import {ParseTranslate} from "../../Layout/ParseTranslate";
import {Section} from "../../Layout/Section";
import {Skillsheet} from "../Skillsheet/Skillsheet";
import {Article} from "./Article";
import {ProjectGallery} from "./Projects/ProjectGallery";

/**
 * A small Skillsheet.
 */
const SmallSkillsheet = Component.Wrapper(Skillsheet)`
	font-size: 0.8em;
	max-height: 40em;
`;

/**
 * My personal portfolio.
 */
export const Portfolio = Component.Translate(class Portfolio extends Component {
	_afterMount() {
		document.title = this.translate("Documents.Articles.Portfolio.Title");
	}

	_onRender() {
		return (
			<Article>
				<Content>
					<Section title={this.translate("Documents.Articles.Portfolio.Title")}>
						<ParseTranslate translationKey="Documents.Articles.Portfolio.Contents">
							<p/>
						</ParseTranslate>
					</Section>
					<ProjectGallery projects={this.properties.projects}/>
				</Content>
			</Article>
		);
	}
}, [
	{
		language: "en",
		translations: {
			Documents: {
				Articles: {
					Portfolio: {
						Title: `Portfolio`,
						Contents: `<0>
							The gallery below shows a brief overview and description of the projects I have worked on.
							Click on any project to visit that project's information page and get more details.
						</0>`
					}
				}
			}
		}
	}, {
		language: "nl",
		translations: {
			Documents: {
				Articles: {
					Portfolio: {
						Title: `Portfolio`,
						Contents: `<0>
							De gallerij hieronder toont een kort overzicht van elk project waar ik aan heb bijgedragen.
							Klik op een project om de pagina van dat project te bezoeken voor meer details.
						</0>`
					}
				}
			}
		}
	}
]);

Component.DefaultProperties(Portfolio, {
	/**
	 * The Projects to display.
	 * @type {Project[]}
	 */
	projects: []
});
