/**
 * Represents a SkillLevel.
 */
export class SkillLevel {
	/**
	 * Creates a new SkillLevel.
	 * @param {Skill} skill The skill.
	 * @param {number} level The skill level.
	 * @param {boolean} isHobby Whether the skill is a hobby.
	 */
	constructor(skill, level, isHobby = false) {
		/**
		 * The skill.
		 * @type {Skill}
		 */
		this.skill = skill;

		/**
		 * The skill level.
		 * @type {number}
		 */
		this.level = level;

		/**
		 * Whether the skill is hobby.
		 * @type {boolean}
		 */
		this.isHobby = isHobby;
	}
}
