import {Component} from "../../Layout/Component";
import {Content} from "../../Layout/Content";
import {ParseTranslate} from "../../Layout/ParseTranslate";
import {Section} from "../../Layout/Section";
import {Article} from "./Article";

/**
 * The NotFoundpage.
 */
export const NotFound = Component.Translate(class NotFound extends Component {
	_afterMount() {
		document.title = this.translate("Documents.Articles.NotFound.Title");
	}

	_onRender() {
		return (
			<Article>
				<Content>
					<Section title={this.translate("Documents.Articles.NotFound.Title")}>
						<ParseTranslate translationKey="Documents.Articles.NotFound.Welcome">
							<p/>
							<p/>
						</ParseTranslate>
					</Section>
				</Content>
			</Article>
		);
	}
}, [
	{
		language: "en",
		translations: {
			Documents: {
				Articles: {
					NotFound: {
						Title: `404 - Not Found`,
						Welcome: `
							<0>
								You have reached the end of the observable universe.
								Your demo of Life Simulator does not provide access to this region of space.
								Please turn back.
							</0>
							<1>(Try using a different link, or navigate using the menu on top ;-) )</1>
						`
					}
				}
			}
		}
	}, {
		language: "nl",
		translations: {
			Documents: {
				Articles: {
					NotFound: {
						Title: `404 - Niet Gevonden`,
						Welcome: `
							<0>
								U heeft het einde van het observeerbare universum bereikt.
								Uw demo van Leven Simulator geeft u geen toegang tot dit gedeelte van de ruimte.
								Keert u A.U.B. om.
							</0>
							<1>(Probeer een andere link te gebruiken, of navigeer met het menu bovenaan ;-) )</1>
						`
					}
				}
			}
		}
	}
]);
