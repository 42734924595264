import {i18next} from "../../../../i18next";
import {CPlusPlusDevelopment} from "../../../Skillsheet/Skills/CPlusPlusDevelopment";
import {English} from "../../../Skillsheet/Skills/English";
import {GitDevelopment} from "../../../Skillsheet/Skills/GitDevelopment";
import {GitLabCIDevelopment} from "../../../Skillsheet/Skills/GitLabCIDevelopment";
import {JetbrainsSoftware} from "../../../Skillsheet/Skills/JetbrainsSoftware";
import {KotlinDevelopment} from "../../../Skillsheet/Skills/KotlinDevelopment";
import {Project} from "../Project";
import Icon from "./Icon.svg";

i18next
	.addResourceBundle("en", "translation", {
		Documents: {
			Articles: {
				Projects: {
					SortPlusPlus: {
						Description: `
							SortPlusPlus is a Jetbrains CLion plugin that enables sorting C++ class members based on regex categories.
							Sorting also creates/removes access modifiers as needed.
						`,
						RoleDescription: `I designed and wrote the plugin.`
					}
				}
			}
		}
	}, true)
	.addResourceBundle("nl", "translation", {
		Documents: {
			Articles: {
				Projects: {
					SortPlusPlus: {
						Description: `
							SortPlusPlus is een Jetbrains CLion plugin die het mogelijk maakt op C++ class members te sorteren gebaseerd op regex categorieën.
							Het sorteren voegt toe/verwijderd ook access modifiers indien nodig.
						`,
						RoleDescription: `Ik heb de plugin ontworpen en geschreven.`
					}
				}
			}
		}
	}, true);

export const SortPlusPlus = new Project("SortPlusPlus", null, null, new URL("https://gitlab.qub1.com/Qub1/SortPlusPlus"), [
	CPlusPlusDevelopment,
	English,
	GitDevelopment,
	GitLabCIDevelopment,
	JetbrainsSoftware,
	KotlinDevelopment
], [Icon]);

function update() {
	SortPlusPlus.description = i18next.t("Documents.Articles.Projects.SortPlusPlus.Description");
	SortPlusPlus.roleDescription = i18next.t("Documents.Articles.Projects.SortPlusPlus.RoleDescription");
}

i18next.on("languageChanged", language => {
	update();
});

update();
