import {i18next} from "../../../../i18next";
import {CSS3Development} from "../../../Skillsheet/Skills/CSS3Development";
import {Dutch} from "../../../Skillsheet/Skills/Dutch";
import {English} from "../../../Skillsheet/Skills/English";
import {GitDevelopment} from "../../../Skillsheet/Skills/GitDevelopment";
import {HTML5Development} from "../../../Skillsheet/Skills/HTML5Development";
import {JavaScriptDevelopment} from "../../../Skillsheet/Skills/JavaScriptDevelopment";
import {PHPDevelopment} from "../../../Skillsheet/Skills/PHPDevelopment";
import {Project} from "../Project";
import HairstudioJoyceImage from "./Hairstudio-Joyce.png";
import HairstudioJoyceImage2 from "./Hairstudio-Joyce2.png";

i18next
	.addResourceBundle("en", "translation", {
		Documents: {
			Articles: {
				Projects: {
					HairstudioJoyce: {
						Description: `Hairstudio Joyce is a family hair salon business that offers an online single-page website where customers can get a preview of various hairstyles that are offered as well as see the price list.`,
						RoleDescription: `I have created and manage the website.`
					}
				}
			}
		}
	}, true)
	.addResourceBundle("nl", "translation", {
		Documents: {
			Articles: {
				Projects: {
					HairstudioJoyce: {
						Description: `Hairstudio Joyce is een familie kapperszaak die een online single-page website aanbiedt waar klanten een voorproefje kunnen krijgen van verscheidene haarstijlen die worden aangeboden en de prijslijst kunnen zien.`,
						RoleDescription: `Ik heb de site ontworpen en beheer deze.`
					}
				}
			}
		}
	}, true);

export const HairstudioJoyce = new Project("Hairstudio Joyce", null, null, new URL("https://hairstudiojoyce.nl"), [
	CSS3Development,
	Dutch,
	English,
	GitDevelopment,
	HTML5Development,
	JavaScriptDevelopment,
	PHPDevelopment
], [
	HairstudioJoyceImage,
	HairstudioJoyceImage2
]);

function update() {
	HairstudioJoyce.description = i18next.t("Documents.Articles.Projects.HairstudioJoyce.Description");
	HairstudioJoyce.roleDescription = i18next.t("Documents.Articles.Projects.HairstudioJoyce.RoleDescription");
}

i18next.on("languageChanged", language => {
	update();
});

update();
