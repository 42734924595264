import React from "react";
import ReactDOM from "react-dom";
import {BrowserRouter} from "react-router-dom";
import "./i18next";
import {Application} from "./Layout/Application";
import WebVitalsReporter from "./WebVitalsReporter";

ReactDOM.render(
	<React.StrictMode>
		<BrowserRouter>
			<Application/>
		</BrowserRouter>
	</React.StrictMode>,
	document.getElementById("Root")
);

WebVitalsReporter();
