import {ContactCard} from "../../Communication/Contacts/ContactCard";
import {Component} from "../../Layout/Component";
import {Content} from "../../Layout/Content";
import {Section} from "../../Layout/Section";
import {Sidebar} from "../../Layout/Sidebar";
import {Article} from "./Article";

/**
 * Shows information about me and the website.
 */
export const About = Component.Translate(class About extends Component {
	_afterMount() {
		document.title = this.translate("Documents.Articles.AboutSite.Title");
	}

	_onRender() {
		return (
			<Article>
				<Content>
					<Section title={this.translate("Documents.Articles.AboutMe.Title")}>
						{this.properties.contact.biography}
					</Section>

					<Section title={this.translate("Documents.Articles.AboutSite.Title")}>
						{this.properties.project.description}
					</Section>
				</Content>
				<Sidebar>
					<ContactCard contact={this.properties.contact}/>
				</Sidebar>
			</Article>
		);
	}
}, [
	{
		language: "en",
		translations: {
			Documents: {
				Articles: {
					AboutMe: {
						Title: `About Me`
					},
					AboutSite: {
						Title: `About Qub1.com`
					}
				}
			}
		}
	}, {
		language: "nl",
		translations: {
			Documents: {
				Articles: {
					AboutMe: {
						Title: `Over mij`
					},
					AboutSite: {
						Title: `Over Qub1.com`
					}
				}
			}
		}
	}
]);

Component.DefaultProperties(About, {
	/**
	 * The Contact to display.
	 * @type {Contact}
	 */
	contact: null,

	/**
	 * The Project that represents the site.
	 * @type {Project}
	 */
	project: null
});
