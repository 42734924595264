import {Component} from "../Layout/Component";

/**
 * The outer wrapper.
 */
const Wrapper = Component.Div`
	align-items: start;
	box-sizing: border-box;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	gap: 0.75em;
	justify-content: space-around;
	overflow-x: hidden;
	overflow-y: auto;
	padding: 0.4em;

	@media (max-width: 25em) {
		padding: 0;
	}
`;

/**
 * An item in the Gallery.
 */
const Item = Component.Div`
	backdrop-filter: blur(0.1em);
	background: rgba(255, 255, 255, 0.075);
	box-shadow: 0.4em 0.4em rgba(0, 0, 0, 0.4), 0 0 0.4em rgba(0, 0, 0, 0.4);
	box-sizing: border-box;
	flex-direction: column;
	overflow: hidden;
	padding: 1em;

	:hover {
		background: rgba(255, 255, 255, 0.1);
		transition: 100ms;
	}

	> * {
		max-width: 100%;
		object-fit: cover;
	}
`;

/**
 * A gallery of media items.
 */
export class Gallery extends Component {
	_onRender() {
		return (
			<Wrapper className={this.properties.className}>
				{this.children.map((child, index) => (
					<Item key={index}>
						{child}
					</Item>
				))}
			</Wrapper>
		);
	}
}
