import {Skybox} from "@qub1/venginejs";
import SkyboxBack from "./corona_bk.png";
import SkyboxBottom from "./corona_dn.png";
import SkyboxFront from "./corona_ft.png";
import SkyboxLeft from "./corona_lf.png";
import SkyboxRight from "./corona_rt.png";
import SkyboxTop from "./corona_up.png";

/**
 * A SpaceSkybox for space.
 */
export class SpaceSkybox extends Skybox {
	/**
	 * Creates a new SpaceSkybox.
	 * @param {Camera} camera The camera to attach to.
	 */
	constructor(camera = null) {
		super(camera);

		// Set the skybox textures
		this.setTextures(
			SkyboxFront,
			SkyboxBack,
			SkyboxTop,
			SkyboxBottom,
			SkyboxLeft,
			SkyboxRight,
			false
		);
	}

	// /**
	//  * Generates a side of the skybox.
	//  */
	// #generateSide() {
	// 	// Generate a side of the skybox
	// 	let side = new THREE.ShaderMaterial({
	// 		uniforms: {
	// 			time: {
	// 				value: this.totalDelta
	// 			},
	// 			resolution: {
	// 				value: {
	// 					x: 800,
	// 					y: 600
	// 				}
	// 			},
	// 			mouse: {
	// 				value: {
	// 					x: 0,
	// 					y: 0
	// 				}
	// 			}
	// 		},
	// 		vertexShader: new SimpleShader().source,
	// 		fragmentShader: new StarfieldShader().source
	// 	});
	//
	// 	// Render the texture on the inside
	// 	side.side = THREE.BackSide;
	//
	// 	return side;
	// }
}
