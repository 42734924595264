/**
 * Represents a Skill.
 */
export class Skill {
	/**
	 * Creates a new Skill.
	 * @param {string} name The name.
	 * @param {Element} icon The skill icon.
	 */
	constructor(name, icon = null) {
		/**
		 * The name.
		 * @type {string}
		 */
		this.name = name;

		/**
		 * The skill icon.
		 * @type {Image}
		 */
		this.icon = icon;
	}
}
