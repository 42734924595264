import {Trans} from "react-i18next";
import {Component} from "./Component";

/**
 * Parses the inner components and translates them using some translations.
 */
export class ParseTranslate extends Component {
	_onRender() {
		return (
			<Trans i18nKey={this.properties.translationKey}>
				{this.children}
			</Trans>
		);
	}
}

Component.DefaultProperties(ParseTranslate, {
	/**
	 * The translation key.
	 * @type {string}
	 */
	translationKey: ""
});
