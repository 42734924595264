import "@fontsource/lobster";
import "@fontsource/vt323";
import {Orbit, Planet, Section, Spaceshow, Target, UFO} from "@qub1/spaceshow";
import {ContactCard} from "../../Communication/Contacts/ContactCard";
import {Component} from "../../Layout/Component";
import {Skillsheet} from "../Skillsheet/Skillsheet";
import {Certificate} from "./Certificate";
import "./CurriculumVitae";

/**
 * The title of a hobby.
 */
const HobbyTitle = Component.H2`
	font-size: 1.3em;
`;

/**
 * The icon of a hobby.
 */
const HobbyIcon = Component.Div`
	font-size: 2em;
	height: 1em;
	width: 1em;
`;

/**
 * The contents displayed over a Planet.
 */
const PlanetContents = Component.Div`
	text-shadow: 0.1em 0.1em 0.1em black;
	z-index: 1;
`;

/**
 * The header of a Planet.
 */
const PlanetCaption = Component.Figcaption`
	align-items: center;
	display: flex;
	flex-direction: column;
	justify-content: center;
	margin: 2em 0;
`;

/**
 * The name of a Planet.
 */
const PlanetName = Component.H2`
	background: rgba(15, 15, 15, 0.6);
	border-radius: 0.5em;
	font-family: VT323, sans-serif;
	padding: 0.05em 0.3em;
	text-shadow: 0.08em 0.08em black;
`;

/**
 * The title of the Planet.
 */
const PlanetTitle = Component.H3`
	font-family: Lobster, sans-serif;
`;

/**
 * The dates of a Degree.
 */
const Dates = Component.Small`
	display: block;
	font-style: italic;
	margin-bottom: 1em;
`;

/**
 * A date.
 */
const DateItem = Component.Span`
	padding: 0 0.5em;
`;

/**
 * The Institution of the Degree.
 */
const PlanetPlace = Component.Div`
	font-size: 0.9em;
`;

/**
 * The contact card.
 */
const SmallContactCard = Component.Wrapper(ContactCard)`
	font-size: 0.75em;
	margin-bottom: 3em;
	width: 100%;
	
	@media (max-width: 50em) {
		font-size: 0.5em;
		margin-bottom: 1em;
	}
`;

/**
 * My personal curriculum vitae Spaceshow.
 */
export const CurriculumVitaeSpaceshow = Component.Translate(class CurriculumVitae extends Component {
		_onRender() {
			return (
				<Spaceshow title={this.translate("documents.curriculumVitae.title")} rocketImage={this.properties.curriculumVitae.contact.image} rocketTargets={
					[
						// Slide 1
						new Target(
							50,
							25,
							(rocket) => {
								rocket.showMessage(this.translate("Documents.CurriculumVitae.Rocket.Message1"), 10000);
							}
						),
						new Target(
							95,
							33
						),
						new Target(
							95,
							67
						),

						// Slide 2
						new Target(
							50,
							100,
							(rocket) => {
								rocket.showMessage(this.translate("Documents.CurriculumVitae.Rocket.Message2"), 10000);
							}
						),
						new Target(
							50,
							150,
							(rocket) => {
								rocket.showMessage(this.translate("Documents.CurriculumVitae.Rocket.Message3"), 10000);
							}
						),

						// Slide 3
						new Target(
							50,
							200,
							(rocket) => {
								rocket.showMessage(this.translate("Documents.CurriculumVitae.Rocket.Message4"), 10000);
							}
						),
						new Target(
							50,
							275
						),

						// Slide 4
						new Target(
							90,
							300,
							(rocket) => {
								rocket.showMessage(this.translate("Documents.CurriculumVitae.Rocket.Message5"), 10000);
							}
						),

						// Slide 5
						new Target(
							90,
							400,
							(rocket) => {
								rocket.showMessage(this.translate("Documents.CurriculumVitae.Rocket.Message6"), 10000);
							}
						),
						new Target(
							60,
							485
						),
						new Target(
							50,
							490
						),
						new Target(
							40,
							485,
							(rocket) => {
								rocket.showMessage(this.translate("Documents.CurriculumVitae.Rocket.Message7"), 10000);
							}
						),
						new Target(
							10,
							400
						),

						// Loop
						new Target(
							10,
							25
						)
					]
				}>
					<Section title={this.translate("documents.curriculumVitae.hobbies")}>
						<Orbit>
							{this.properties.curriculumVitae.skillLevels
								.filter(skill => skill.isHobby)
								.map((hobby, index) => (
									<UFO key={index}>
										<HobbyTitle>{hobby.skill.name}</HobbyTitle>
										<HobbyIcon>{hobby.skill.icon}</HobbyIcon>
									</UFO>
								))}
						</Orbit>
					</Section>
					<Section title={this.translate("documents.curriculumVitae.skills")}>
						<Skillsheet skillLevels={this.properties.curriculumVitae.skillLevels} projects={this.properties.curriculumVitae.projects}/>
					</Section>
					<Section title={this.translate("documents.curriculumVitae.professionalExperience")}>
						<Orbit>
							{this.properties.curriculumVitae.jobs.map((job, index) => {
								const planet = Planet.CreateRandom();
								return Component.Clone(planet, {
									key: index,
									footer: (
										<PlanetCaption>
											<PlanetName>{job.name}</PlanetName>
										</PlanetCaption>
									),
									children: (
										<>
											{planet.props.children}
											<PlanetContents>
												<PlanetTitle>{job.companies.map((institution, index) => institution.name).join(" & ")}</PlanetTitle>
												<Dates>
													<DateItem>{job.startDate.getMonth() + 1} - {job.startDate.getFullYear()}</DateItem>
													<DateItem>{job.endDate.getMonth() + 1} - {job.endDate.getFullYear()}</DateItem>
												</Dates>
											</PlanetContents>
										</>
									)
								});
							})}
						</Orbit>
					</Section>
					<Section title={this.translate("documents.curriculumVitae.degrees")}>
						<Orbit>
							{this.properties.curriculumVitae.degrees.map((degree, index) => {
								// const planet = Planet.CreateRandom();
								// return Component.Clone(planet, {
								// 	key: index,
								// 	footer: (
								// 		<PlanetCaption>
								// 			<PlanetName>{degree.name}</PlanetName>
								// 		</PlanetCaption>
								// 	),
								// 	children: (
								// 		<>
								// 			{planet.props.children}
								// 			<PlanetContents>
								// 				<PlanetTitle>{degree.minor}</PlanetTitle>
								// 				<PlanetPlace>{degree.institutions.map((institution, index) => institution.name).join(" & ")}</PlanetPlace>
								// 				<Dates>
								// 					<DateItem>{degree.startDate.getMonth() + 1} - {degree.startDate.getFullYear()}</DateItem> - <DateItem>{degree.endDate.getMonth() + 1} - {degree.endDate.getFullYear()}</DateItem>
								// 				</Dates>
								// 			</PlanetContents>
								// 		</>
								// 	)
								// });
								return (
									<Certificate
										key={index}
										title={degree.name}
										subtitle={degree.minor}
										place={degree.institutions.map((institution, index) => institution.name).join(" & ")}
										dates={<>
											<DateItem>{degree.startDate.getMonth() + 1} - {degree.startDate.getFullYear()}</DateItem>
											<DateItem>{degree.endDate.getMonth() + 1} - {degree.endDate.getFullYear()}</DateItem>
										</>}
										name={`${this.properties.curriculumVitae.contact.person.name.firstName} ${this.properties.curriculumVitae.contact.person.name.lastName}`}
									/>
								);
							})}
						</Orbit>
					</Section>
					<Section title={
						<>
							<SmallContactCard contact={this.properties.curriculumVitae.contact}/>
							{this.translate("documents.curriculumVitae.biography")}
						</>
					}>
						{this.properties.curriculumVitae.contact.biography}
					</Section>
				</Spaceshow>
			);
		}
	}

	,
	[
		{
			language: "en",
			translations: {
				Documents: {
					CurriculumVitae: {
						Rocket: {
							Message1: `Hi! Welcome to my website`,
							Message2: `These are the places where I learned to do what I do`,
							Message3: `Ooh, that planet looks cool!`,
							Message4: `These are the places I've worked at`,
							Message5: `These are my skills`,
							Message6: `And finally, these are some of my favorite activities`,
							Message7: `Hope you enjoyed the tour! I'm going for another round ;)`
						}
					}
				}
			}
		}
		, {
		language: "nl"
		,
		translations: {
			Documents: {
				CurriculumVitae: {
					Rocket: {
						Message1: `Hi! Welkom op mijn website`,
						Message2: `Dit zijn de plekken waar ik heb geleerd te doen wat ik doe`,
						Message3: `Ooh, die planeet ziet er cool uit!`,
						Message4: `Dit zijn de plekken waar ik heb gewerkt`,
						Message5: `Dit zijn mijn vaardigheden`,
						Message6: `En als laatste, dit zijn een aantal van mijn favoriete activiteiten`,
						Message7: `Ik hoop dat je van de tour hebt genoten! Ik ga nog een rondje ;)`
					}
				}
			}
		}
	}
	])
;
