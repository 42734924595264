import SpanishIcon from "language-icons/icons/es.svg";
import {i18next} from "../../../i18next";
import {Skill} from "./Skill";

i18next
	.addResourceBundle("en", "translation", {
		Documentation: {
			Skillsheet: {
				Skills: {
					Spanish: `Spanish`
				}
			}
		}
	}, true)
	.addResourceBundle("nl", "translation", {
		Documentation: {
			Skillsheet: {
				Skills: {
					Spanish: `Spaans`
				}
			}
		}
	}, true);

export const Spanish = new Skill(null, <img src={SpanishIcon} style={{
	height: "1em",
	width: "1em"
}}/>);

function update() {
	Spanish.name = i18next.t("Documentation.Skillsheet.Skills.Spanish");
}

i18next.on("languageChanged", language => {
	update();
});

update();
