import "@fontsource/oswald";
import {Link} from "react-router-dom";
import {Component} from "./Component";
import {LanguageSwitcher} from "./LanguageSwitcher";

/**
 * The outer wrapper.
 */
const Wrapper = Component.Nav`
	align-items: center;
	display: flex;
	flex-direction: row;
	gap: 2em;
	justify-content: center;
	width: 100%;

	@media (max-width: 800px) {
		flex-direction: column;
	}
`;

/**
 * The navigation menu.
 */
const Menu = Component.Ul`
	align-items: center;
	display: flex;
	flex-direction: row;
	justify-content: center;
	list-style-type: none;

	@media (max-width: 450px) {
		flex-direction: column;
	}
`;

/**
 * A navigation option.
 */
const MenuOption = Component.Li``;

/**
 * A link in the navigation menu.
 */
const MenuLink = Component.Wrapper(Link)`
	color: white;
	font-family: Oswald, sans-serif;
	font-size: 1.2em;
	padding: 0 1em;
	text-decoration: none;
	text-shadow: 4px 4px rgba(0, 0, 0, 0.5);
`;

/**
 * Represents the navigation menu.
 */
export const Navigation = Component.Translate(class Navigation extends Component {
	_onRender() {
		return (
			<Wrapper>
				<Menu>
					<MenuOption>
						<MenuLink to="/Home">{this.translate("Navigation.Home")}</MenuLink>
					</MenuOption>
					<MenuOption>
						<MenuLink to="/Portfolio">{this.translate("Navigation.Portfolio")}</MenuLink>
					</MenuOption>
					<MenuOption>
						<MenuLink to="/Skills">{this.translate("Navigation.Skills")}</MenuLink>
					</MenuOption>
					<MenuOption>
						<MenuLink to="/CurriculumVitae">{this.translate("Navigation.CurriculumVitae")}</MenuLink>
					</MenuOption>
					<MenuOption>
						<MenuLink to="/About">{this.translate("Navigation.About")}</MenuLink>
					</MenuOption>
				</Menu>
				<LanguageSwitcher/>
			</Wrapper>
		);
	}
}, [
	{
		language: "en",
		translations: {
			Navigation: {
				Home: `Home`,
				Portfolio: `Portfolio`,
				Skills: `Skills`,
				CurriculumVitae: `Curriculum Vitae`,
				About: `About`
			}
		}
	}, {
		language: "nl",
		translations: {
			Navigation: {
				Home: `Startpagina`,
				Portfolio: `Portfolio`,
				Skills: `Vaardigheden`,
				CurriculumVitae: `Curriculum Vitae`,
				About: `Over`
			}
		}
	}
]);
