import {i18next} from "../../../../i18next";
import {CSharpDevelopment} from "../../../Skillsheet/Skills/CSharpDevelopment";
import {English} from "../../../Skillsheet/Skills/English";
import {GameDevelopment} from "../../../Skillsheet/Skills/GameDevelopment";
import {GitDevelopment} from "../../../Skillsheet/Skills/GitDevelopment";
import {VisualStudio} from "../../../Skillsheet/Skills/VisualStudio";
import {XNADevelopment} from "../../../Skillsheet/Skills/XNADevelopment";
import {Project} from "../Project";
import Logo from "./Logo.png";
import Multiplayer from "./Multiplayer.png";

i18next
	.addResourceBundle("en", "translation", {
		Documents: {
			Articles: {
				Projects: {
					MultiLink: {
						Description: `
							MultiLink is a dungeon-crawler where players join forces to solve the many puzzles and defeat the enemies crawling in the depths.
							The game supports cooperative multiplayer over a network.
						`,
						RoleDescription: `I wrote the client-side networking code, as well as some of the other parts of the game such as interface elements.`
					}
				}
			}
		}
	}, true)
	.addResourceBundle("nl", "translation", {
		Documents: {
			Articles: {
				Projects: {
					MultiLink: {
						Description: `
							MultiLink is een dungeon-crawler waarbij spelers de handen ineenslaan om de vele puzzels op te lossen en de vijanden te verslaan in de duisternis.
							Het spel ondersteunt coöperatieve multiplayer over een netwerk.
						`,
						RoleDescription: `Ik heb de client-side networking code geschreven, en ook een aantal andere delen van het spel zoals interface elementen.`
					}
				}
			}
		}
	}, true);

export const MultiLink = new Project("MultiLink", null, null, new URL("https://gitlab.qub1.com/Education/University/Universiteit-Utrecht/Gametechnologie-introductieproject/multilink"), [
	CSharpDevelopment,
	English,
	GameDevelopment,
	GitDevelopment,
	VisualStudio,
	XNADevelopment
], [
	Logo,
	Multiplayer
]);

function update() {
	MultiLink.description = i18next.t("Documents.Articles.Projects.MultiLink.Description");
	MultiLink.roleDescription = i18next.t("Documents.Articles.Projects.MultiLink.RoleDescription");
}

i18next.on("languageChanged", language => {
	update();
});

update();
