import "@fontsource/lobster";
import {Link} from "react-router-dom";
import {Component} from "./Component";
import {Navigation} from "./Navigation";
import {ParseTranslate} from "./ParseTranslate";
import {Separator} from "./Separator";

const Wrapper = Component.Header`
	align-items: center;
	background: rgba(255, 255, 255, 0.03) radial-gradient(circle, rgba(255, 255, 255, 0.03) 0%, rgba(0, 0, 0, 0.03) 99%);
	box-shadow: 0 0 1em rgba(0, 0, 0, 0.4);
	display: flex;
	flex-direction: column;
	justify-content: center;
	margin-bottom: 1em;
	text-align: center;
	width: 100%;
`;

const Title = Component.H1`
	color: #5ba6d4;
	font-family: Lobster, sans-serif;
	font-size: 3em;
	font-weight: lighter;
	margin: 0.1em 0;
	text-shadow: 0.05em 0.05em rgba(0, 0, 0, 0.75);
`;

const HomeLink = Component.Wrapper(Link)`
	color: #bf4e4e;
	text-decoration: none;
`;

const OverflowDown = Component.Div`
	margin-bottom: -1em;
	width: 100%;
`;

/**
 * Represents the top of the Page.
 */
export const Header = Component.Translate(class Header extends Component {
	_onRender() {
		return (
			<Wrapper>
				<Title>
					<ParseTranslate translationKey="Layout.Header.Title">
						<HomeLink to="/"/>
					</ParseTranslate>
				</Title>
				<Navigation/>
				<OverflowDown>
					<Separator/>
				</OverflowDown>
			</Wrapper>
		);
	}
}, [
	{
		language: "en",
		translations: {
			Layout: {
				Header: {
					Title: `Welcome to <0>Qub1.com</0>`
				}
			}
		}
	},
	{
		language: "nl",
		translations: {
			Layout: {
				Header: {
					Title: `Welkom op <0>Qub1.com</0>`
				}
			}
		}
	}
]);
