import {Component} from "./Component";

const Wrapper = Component.Aside`
	max-width: 15em;
	width: 100%;
	
	@media (max-width: 500px) {
		max-width: 500px;
	}
`;

/**
 * Shows content off to the side in the Body.
 */
export class Sidebar extends Component {
	_onRender() {
		return (
			<Wrapper>
				{this.children}
			</Wrapper>
		);
	}
}
