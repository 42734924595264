import {i18next} from "../../../../i18next";
import {BoostDevelopment} from "../../../Skillsheet/Skills/BoostDevelopment";
import {CMakeDevelopment} from "../../../Skillsheet/Skills/CMakeDevelopment";
import {CPlusPlusDevelopment} from "../../../Skillsheet/Skills/CPlusPlusDevelopment";
import {CUDADevelopment} from "../../../Skillsheet/Skills/CUDADevelopment";
import {DebianLinux} from "../../../Skillsheet/Skills/DebianLinux";
import {English} from "../../../Skillsheet/Skills/English";
import {GitDevelopment} from "../../../Skillsheet/Skills/GitDevelopment";
import {GitLabCIDevelopment} from "../../../Skillsheet/Skills/GitLabCIDevelopment";
import {JupyterDevelopment} from "../../../Skillsheet/Skills/JupyterDevelopment";
import {LaTeXDevelopment} from "../../../Skillsheet/Skills/LaTeXDevelopment";
import {LibreOffice} from "../../../Skillsheet/Skills/LibreOffice";
import {PythonDevelopment} from "../../../Skillsheet/Skills/PythonDevelopment";
import {Project} from "../Project";
import Visualizer1 from "./Visualizer1.png";
import Visualizer2 from "./Visualizer2.png";

i18next
	.addResourceBundle("en", "translation", {
		Documents: {
			Articles: {
				Projects: {
					EnergyManager: {
						Description: `EnergyManager is a framework and accompanying sandbox that allow users to monitor applications and adjust system parameters in real time to save energy depending on characteristics of the application workload.`,
						RoleDescription: `I designed the framework and wrote it.`
					}
				}
			}
		}
	}, true)
	.addResourceBundle("nl", "translation", {
		Documents: {
			Articles: {
				Projects: {
					EnergyManager: {
						Description: `EnergyManager is een framework en bijgeleverde sandbox die gebruikers in staat stellen om applicaties te monitoren en systeem parameters te veranderen in real-time om energie te besparen gebaseerd op karakteristieken van de applicatie werklading.`,
						RoleDescription: `Ik heb het framework ontworpen en geschreven.`
					}
				}
			}
		}
	}, true);

export const EnergyManager = new Project("EnergyManager", null, null, new URL("https://gitlab.qub1.com/Education/University/Vrije-Universiteit/Master-Project/EnergyManager"), [
	BoostDevelopment,
	CMakeDevelopment,
	CPlusPlusDevelopment,
	CUDADevelopment,
	DebianLinux,
	English,
	GitDevelopment,
	GitLabCIDevelopment,
	JupyterDevelopment,
	LaTeXDevelopment,
	LibreOffice,
	PythonDevelopment
], [
	Visualizer1,
	Visualizer2
]);

function update() {
	EnergyManager.description = i18next.t("Documents.Articles.Projects.EnergyManager.Description");
	EnergyManager.roleDescription = i18next.t("Documents.Articles.Projects.EnergyManager.RoleDescription");
}

i18next.on("languageChanged", language => {
	update();
});

update();
