import {faGit} from "@fortawesome/free-brands-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {i18next} from "../../../i18next";
import {Skill} from "./Skill";

i18next
	.addResourceBundle("en", "translation", {
		Documentation: {
			Skillsheet: {
				Skills: {
					GitDevelopment: `Git Development`
				}
			}
		}
	}, true)
	.addResourceBundle("nl", "translation", {
		Documentation: {
			Skillsheet: {
				Skills: {
					GitDevelopment: `Git Ontwikkeling`
				}
			}
		}
	}, true);

export const GitDevelopment = new Skill(null, <FontAwesomeIcon icon={faGit}/>);

function update() {
	GitDevelopment.name = i18next.t("Documentation.Skillsheet.Skills.GitDevelopment");
}

i18next.on("languageChanged", language => {
	update();
});

update();
