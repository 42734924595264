import {i18next} from "../../../../i18next";
import {CSharpDevelopment} from "../../../Skillsheet/Skills/CSharpDevelopment";
import {Dutch} from "../../../Skillsheet/Skills/Dutch";
import {English} from "../../../Skillsheet/Skills/English";
import {GitDevelopment} from "../../../Skillsheet/Skills/GitDevelopment";
import {VisualStudio} from "../../../Skillsheet/Skills/VisualStudio";
import {Project} from "../Project";
import Editor from "./Editor.png";
import MapEditor from "./MapEditor.png";

i18next
	.addResourceBundle("en", "translation", {
		Documents: {
			Articles: {
				Projects: {
					RoadMapper: {
						Description: `
							RoadMapper is a program to simulate real-time traffic and analyze congestion.
							Users can use a visual map editor to create their own intersections, add traffic lights and set vehicle flow rates.
						`,
						RoleDescription: `I wrote and designed the program.`
					}
				}
			}
		}
	}, true)
	.addResourceBundle("nl", "translation", {
		Documents: {
			Articles: {
				Projects: {
					RoadMapper: {
						Description: `
							RoadMapper is een programma om echt verkeer te simuleren en files te analyseren.
							Gebruikers kunnen een visuele kaart bewerkingstool gebruiken om hun eigen kruispunten te maken, stoplichten toe te voegen en de doorstroming van voertuigen te bepalen.
						`,
						RoleDescription: `Ik heb de applicatie geschreven en ontworpen.`
					}
				}
			}
		}
	}, true);

export const RoadMapper = new Project("RoadMapper", null, null, new URL("https://gitlab.qub1.com/Education/High-School/Fons-Vitae-Lyceum/Profielwerkstuk/roadmapper"), [
	CSharpDevelopment,
	Dutch,
	GitDevelopment,
	VisualStudio
], [
	MapEditor,
	Editor
]);

function update() {
	RoadMapper.description = i18next.t("Documents.Articles.Projects.RoadMapper.Description");
	RoadMapper.roleDescription = i18next.t("Documents.Articles.Projects.RoadMapper.RoleDescription");
}

i18next.on("languageChanged", language => {
	update();
});

update();
