import {i18next} from "../../../i18next";
import {CPlusPlusDevelopment} from "../../Skillsheet/Skills/CPlusPlusDevelopment";
import {DoxygenDocumentation} from "../../Skillsheet/Skills/DoxygenDocumentation";
import {English} from "../../Skillsheet/Skills/English";
import {GameDevelopment} from "../../Skillsheet/Skills/GameDevelopment";
import {GitDevelopment} from "../../Skillsheet/Skills/GitDevelopment";
import {OpenGLDevelopment} from "../../Skillsheet/Skills/OpenGL";
import {Project} from "./Project";

i18next
	.addResourceBundle("en", "translation", {
		Documents: {
			Articles: {
				Projects: {
					Vengine: {
						Description: `Vengine is a modern C++ voxel-based simulation engine that features a built-in package manager that players can use to upload and share new game content in the form of modification packages.`,
						RoleDescription: `I designed and wrote the engine.`
					}
				}
			}
		}
	}, true)
	.addResourceBundle("nl", "translation", {
		Documents: {
			Articles: {
				Projects: {
					Vengine: {
						Description: `Vengine is een moderne C++ voxel-gebaseerde simulatie engine die spelers in staat stelt om modificatie pakketten te creëren en delen.`,
						RoleDescription: `Ik heb de engine ontworpen en geschreven.`
					}
				}
			}
		}
	}, true);

export const Vengine = new Project("Vengine (W.I.P.)", null, null, new URL("https://gitlab.qub1.com/Qub1/Vengine"), [
	CPlusPlusDevelopment,
	DoxygenDocumentation,
	English,
	GameDevelopment,
	GitDevelopment,
	OpenGLDevelopment
]);

function update() {
	Vengine.description = i18next.t("Documents.Articles.Projects.Vengine.Description");
	Vengine.roleDescription = i18next.t("Documents.Articles.Projects.Vengine.RoleDescription");
}

i18next.on("languageChanged", language => {
	update();
});

update();
