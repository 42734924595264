import {faChevronDown, faChevronUp} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Link} from "react-router-dom";
import {Component} from "../../Layout/Component";
import {Gallery} from "../../Multimedia/Gallery";

/**
 * The outer wrapper.
 */
const Wrapper = Component.Wrapper(Gallery)`
	align-items: start;
`;

/**
 * A single Skill.
 */
const SkillItem = Component.Div`
	box-sizing: border-box;
	display: flex;
	flex-direction: column;
	justify-content: center;
	min-height: 5em;
	min-width: 18em;
	padding-bottom: 1em;
	position: relative;
`;

/**
 * A Skill property.
 */
const SkillColumn = Component.Div`
	display: flex;
	flex-direction: column;
	height: 100%;
	justify-content: center;
`;

/**
 * A Skill bar that shows a Skill's icon, name and level.
 */
const SkillBar = Component.Div`
	align-items: center;
	display: flex;
	flex-direction: row;
	gap: 1em;
	justify-content: center;

	${SkillColumn}:nth-child(2) {
		flex: 1;
	}
`;

/**
 * A Skill icon.
 */
const SkillIcon = Component.Div`
	align-items: center;
	display: flex;
	font-size: 3em;
	height: 1em;
	justify-content: center;
	width: 1.2em;

	> * {
		border-radius: 0.5em;
		height: 1em;
		width: 1em;
	}
`;

/**
 * A Skill name.
 */
const SkillName = Component.Div`
`;

/**
 * The Skill level.
 */
const SkillLevel = Component.Small`
`;

/**
 * The container for the skill level bar.
 */
const SkillLevelBarContainer = Component.Div`
	background: gray;
	border-radius: 1em;
	border: 0.05em solid black;
	box-shadow: inset 0 0 0.3em darkgray;
	height: 0.8em;
	width: 100%;
`;

/**
 * A Skill level bar.
 */
const SkillLevelBar = Component.Div`
	background: magenta;
	border-radius: 1em;
	border: 0.1em solid purple;
	box-shadow: inset 0 0 0.3em darkmagenta;
	width: ${properties => properties.level / 5 * 100}%;
	height: 100%;
	margin-top: -0.05em;
	margin-left: -0.05em;
`;

/**
 * The button to expand a skill.
 */
const DetailsButton = Component.Wrapper(SkillColumn)`
	align-items: center;
	background: rgb(255, 255, 255, 0.1);
	display: flex;
	flex-direction: column;
	height: 1em;
	justify-content: center;
	margin: 1.1em -1em -2.25em -1em;
	right: 0;
`;

/**
 * The details panel.
 */
const DetailsPanel = Component.Div`
	background: rgb(0, 0, 0, 0.1);
	box-sizing: border-box;
	margin: 2.25em -1em -2.25em -1em;
	padding: 1em;

	ul {
		margin-left: 1em;
	}
`;

/**
 * A skillsheet that displays the levels of various skills.
 */
export const Skillsheet = Component.Translate(class Skillsheet extends Component {
	/**
	 * Creates a new Skillsheet.
	 * @param properties The properties.
	 */
	constructor(properties) {
		super(properties);

		// Keep track of collapse state per skill
		const skillStates = [];
		for (let skillLevel of this.props.skillLevels) {
			skillStates.push({
				skillLevel: skillLevel,
				collapsed: true
			});
		}
		this.state = {
			skillStates: skillStates
		};
	}

	_onRender() {
		return (
			<Wrapper className={this.properties.className}>
				{
					[]
						.concat(this.state.skillStates)
						// Sort the skills by their level and then their name so the best skills are on top
						.sort((left, right) => left.skillLevel.level === right.skillLevel.level ? (
							left.skillLevel.skill.name > right.skillLevel.skill.name ? 1 : -1
						) : (
							left.skillLevel.level < right.skillLevel.level ? 1 : -1
						))
						.map((skillState, index) => {
							const skillLevel = skillState.skillLevel;

							// Filter the projects to get those that contain this skill
							const skillProjects = this.properties.projects.filter(project => project.skills.includes(skillLevel.skill));

							// Create a reference for the current skill
							const detailsPanelReference = Component.CreateReference();
							const detailsButtonReference = Component.CreateReference();

							return (
								<SkillItem key={index}>
									<SkillBar>
										<SkillColumn>
											<SkillIcon>
												{skillLevel.skill.icon}
											</SkillIcon>
										</SkillColumn>
										<SkillColumn>
											<SkillName>{skillLevel.skill.name}</SkillName>
											<SkillLevel>lvl. {skillLevel.level}/5</SkillLevel>
											<SkillLevelBarContainer>
												<SkillLevelBar level={skillLevel.level}/>
											</SkillLevelBarContainer>
										</SkillColumn>
									</SkillBar>
									{skillProjects.length > 0 && (
										<DetailsButton ref={detailsButtonReference} onClick={(event) => {
											skillState.collapsed = !skillState.collapsed;
											this.setState({});
										}}>
											{skillState.collapsed ? <FontAwesomeIcon icon={faChevronDown}/> : <FontAwesomeIcon icon={faChevronUp}/>}
										</DetailsButton>
									)}
									{!skillState.collapsed && (
										<DetailsPanel ref={detailsPanelReference}>
											{this.translate("Documents.Skillsheet.RelatedProjects")}:<br/>
											{skillProjects.length > 0 ? (
												<ul>
													{skillProjects
														.map((project, index) => (
															<li key={index}>
																<Link to={"/Project/" + project.name.replaceAll(/\s/g, "")} target="_blank">
																	{project.name}
																</Link>
															</li>
														))}
												</ul>
											) : "N/A"}
										</DetailsPanel>
									)}
								</SkillItem>
							);
						})
				}
			</Wrapper>
		);
	}
}, [
	{
		language: "en",
		translations: {
			Documents: {
				Skillsheet: {
					RelatedProjects: `Related projects`
				}
			}
		}
	}, {
		language: "nl",
		translations: {
			Documents: {
				Skillsheet: {
					RelatedProjects: `Gerelateerde projecten`
				}
			}
		}
	}
]);

Component.DefaultProperties(Skillsheet, {
	/**
	 * The Projects to display as relevant Projects.
	 * @type {Project[]}
	 */
	projects: [],

	/**
	 * The SkillLevels to display.
	 * @type {SkillLevel[]}
	 */
	skillLevels: []
});
