import {Component} from "../../Layout/Component";

/**
 * The outer wrapper.
 */
const Wrapper = Component.Div`
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	font-size: 0.75em;
	gap: 0.25em;
	white-space: nowrap;
`;

/**
 * A Skill.
 */
const Skill = Component.Div`
	align-items: center;
	background: rgba(0, 0, 0, 0.4);
	border-radius: 1em;
	color: white;
	display: flex;
	flex-direction: row;
	gap: 0.4em;
	padding: 0.1em 0.8em 0.1em 0;
	text-shadow: 0.1em 0.1em black;
`;

/**
 * The icon of a Skill.
 */
const SkillIcon = Component.Div`
	background: rgba(255, 255, 255, 0.2);
	border-radius: 1em;
	margin: -0.1em;
	min-width: 1em;
	padding: 0.2em 0.4em;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
`;

/**
 * An overview of the Skills associated with something.
 */
export class SkillsOverview extends Component {
	_onRender() {
		return (
			<Wrapper className={this.properties.className}>
				{this.properties.skills.map((skill, index) => (
					<Skill key={index}>
						<SkillIcon>{skill.icon}</SkillIcon> {skill.name}
					</Skill>
				))}
			</Wrapper>
		);
	}
}

Component.DefaultProperties(SkillsOverview, {
	/**
	 * The Skills to display.
	 * @type {Skill[]}
	 */
	skills: []
});
