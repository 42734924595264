import "@fontsource/lobster";
import {Planet} from "@qub1/spaceshow";
import randomColor from "randomcolor";
import {Component} from "./Component";

/**
 * The outer wrapper.
 */
const Wrapper = Component.Section`
	background: rgba(255, 255, 255, 0.03) radial-gradient(circle, rgba(255, 255, 255, 0.03) 0%, rgba(0, 0, 0, 0.03) 99%);
	box-shadow: 0.4em 0.4em rgba(0, 0, 0, 0.4), 0 0 1em rgba(0, 0, 0, 0.4);
	box-sizing: border-box;
	color: white;
	display: flex;
	flex-direction: column;
	gap: 0.75em;
	padding: 1em 2em;
	text-shadow: 0.075em 0.075em 0.1em black;
	width: 100%;

	@media (max-width: 500px) {
		padding: 0.5em 1em;
	}
`;

/**
 * The title of the section.
 */
const Title = Component.H1`
	align-items: center;
	color: ${properties => properties.color};
	display: flex;
	flex-direction: row;
	font-family: Lobster, sans-serif;
	font-weight: lighter;
	font-size: 1.5em;
	gap: 0.8em;
	justify-content: start;
	text-shadow: 0.05em 0.05em rgba(0, 0, 0, 0.75);
`;

/**
 * Represents a portion of the Content of a Page.
 */
export class Section extends Component {
	_onRender() {
		return (
			<Wrapper>
				<Title color={this.properties.color === "random" ? randomColor({
					seed: this.properties.title.toLowerCase(),
					luminosity: "bright",
					format: "hex"
				}) : this.properties.color}>{Component.Clone(
					Planet.CreateRandom(),
					{
						diameter: "1em"
					}
				)} {this.properties.title}</Title>
				{this.children}
			</Wrapper>
		);
	}
}

Component.DefaultProperties(Section, {
	color: "random"
});
