import copyStyles from "copy-styles";
import React, {Component as ReactComponent} from "react";
import {withTranslation} from "react-i18next";
import styled from "styled-components";
import {i18next} from "../i18next";

/**
 * An interface component.
 */
export class Component extends ReactComponent {
	/**
	 * Creates a new Component.
	 * @param properties The properties.
	 */
	constructor(properties) {
		super(properties);
	}

	/**
	 * Gets the children.
	 */
	get children() {
		return this.properties.children;
	}

	/**
	 * Gets the properties.
	 */
	get properties() {
		return this.props;
	}

	/**
	 * Calculates the inner height of the Element.
	 * @param {Element} element The Element.
	 * @return {number} The inner height.
	 */
	static CalculateElementInnerHeight(element) {
		const computed = getComputedStyle(element);
		const padding = parseInt(computed.paddingTop) + parseInt(computed.paddingBottom);

		return element.clientHeight - padding;
	}

	/**
	 * Calculates the inner width of the Element.
	 * @param {Element} element The Element.
	 * @return {number} The inner width.
	 */
	static CalculateElementInnerWidth(element) {
		const computed = getComputedStyle(element);
		const padding = parseInt(computed.paddingLeft) + parseInt(computed.paddingRight);

		return element.clientWidth - padding;
	}

	/**
	 * Calculates the style of the provided Element.
	 * @param {Element} element The Element.
	 * @constructor
	 */
	static CalculateElementStyle(element) {
		// Calculate the computed style
		const computedStyle = window.getComputedStyle(element);

		// Calculate the css
		return Object.values(computedStyle).reduce(
			(css, propertyName) =>
				`${css}${propertyName}:${computedStyle.getPropertyValue(
					propertyName
				)};`
		);
	}

	/**
	 * Sets the default properties for a Component.
	 * @param component The component.
	 * @param properties The properties.
	 * @constructor
	 */
	static DefaultProperties(component, properties) {
		component.defaultProps = properties;
	}

	/**
	 * Clones the specified Component.
	 * @param {Component} component The Component to clone.
	 * @param properties The new properties.
	 * @constructor
	 */
	static Clone(component, properties = {key: Component._key++}) {
		return React.cloneElement(component, properties);
	}

	/**
	 * Clones an element.
	 * @param {Element} element The element to clone.
	 * @param {boolean} cloneComputedStyle Whether to clone the computed style to the cloned nodes as an inline style attribute.
	 */
	static CloneElement(element, cloneComputedStyle = false) {
		// Clone the element
		const clone = element.cloneNode(true);

		/**
		 * Clones the styles.
		 * @param {Element} sourceElement The source Element.
		 * @param {Element} targetElement The target Element.
		 */
		const cloneStyle = (sourceElement, targetElement) => {
			if (sourceElement instanceof Element && targetElement instanceof Element) {
				// Copy the style for the current element
				copyStyles(sourceElement, targetElement);

				// Process the children
				for (let childIndex = 0; childIndex < sourceElement.childNodes.length; ++childIndex) {
					cloneStyle(sourceElement.childNodes[childIndex], targetElement.childNodes[childIndex]);
				}
			}
		};

		// Clone the CSS
		if (cloneComputedStyle) {
			cloneStyle(element, clone);
		}

		return clone;
	}

	/**
	 * Creates a new Component reference.
	 * @constructor
	 */
	static CreateReference() {
		return React.createRef();
	}

	/**
	 * Translates the specified Component.
	 * @param {*} component The Component to translate.
	 * @param {*[]} translations The translations.
	 * @constructor
	 */
	static Translate(component, translations = []) {
		// Add the translation bundles
		for (let bundle of translations) {
			i18next.addResourceBundle(bundle.language, "translation", bundle.translations, true);
		}

		return withTranslation()(component);
	}

	/**
	 * Creates a wrapper element.
	 * @param {*} element The element to wrap.
	 * @constructor
	 */
	static Wrapper(element) {
		return styled(element);
	}

	/**
	 * Creates an a element.
	 * @constructor
	 */
	static A() {
		return styled.a(...arguments);
	}

	/**
	 * Creates an article element.
	 * @constructor
	 */
	static Article() {
		return styled.article(...arguments);
	}

	/**
	 * Creates an aside element.
	 * @constructor
	 */
	static Aside() {
		return styled.aside(...arguments);
	}

	/**
	 * Creates a blockquote element.
	 * @constructor
	 */
	static Blockquote() {
		return styled.blockquote(...arguments);
	}

	/**
	 * Creates a button element.
	 * @constructor
	 */
	static Button() {
		return styled.button(...arguments);
	}

	/**
	 * Creates a div element.
	 * @constructor
	 */
	static Div() {
		return styled.div(...arguments);
	}

	/**
	 * Creates a figcaption element.
	 * @constructor
	 */
	static Figcaption() {
		return styled.figcaption(...arguments);
	}

	/**
	 * Creates a figure element.
	 * @constructor
	 */
	static Figure() {
		return styled.figure(...arguments);
	}

	/**
	 * Creates a footer element.
	 * @constructor
	 */
	static Footer() {
		return styled.footer(...arguments);
	}

	/**
	 * Creates a h1 element.
	 * @constructor
	 */
	static H1() {
		return styled.h1(...arguments);
	}

	/**
	 * Creates a h2 element.
	 * @constructor
	 */
	static H2() {
		return styled.h2(...arguments);
	}

	/**
	 * Creates a h3 element.
	 * @constructor
	 */
	static H3() {
		return styled.h3(...arguments);
	}

	/**
	 * Creates a header element.
	 * @constructor
	 */
	static Header() {
		return styled.header(...arguments);
	}

	/**
	 * Creates an img element.
	 * @constructor
	 */
	static Img() {
		return styled.img(...arguments);
	}

	/**
	 * Creates a li element.
	 * @constructor
	 */
	static Li() {
		return styled.li(...arguments);
	}

	/**
	 * Creates a nav element.
	 * @constructor
	 */
	static Nav() {
		return styled.nav(...arguments);
	}

	/**
	 * Creates a p element.
	 * @constructor
	 */
	static P() {
		return styled.p(...arguments);
	}

	/**
	 * Creates a section element.
	 * @constructor
	 */
	static Section() {
		return styled.section(...arguments);
	}

	/**
	 * Creates a small element.
	 * @constructor
	 */
	static Small() {
		return styled.small(...arguments);
	}

	/**
	 * Creates a span element.
	 * @constructor
	 */
	static Span() {
		return styled.span(...arguments);
	}

	/**
	 * Creates a table element.
	 * @constructor
	 */
	static Table() {
		return styled.table(...arguments);
	}

	/**
	 * Creates an ul element.
	 * @constructor
	 */
	static Ul() {
		return styled.ul(...arguments);
	}

	/**
	 * Retrieves the translation if the component is translated.
	 * @param translationKey The translation key.
	 * @return {string} The translation.
	 */
	translate(translationKey) {
		return this.properties.t(translationKey);
	}

	componentDidMount() {
		this._afterMount();
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		this._afterUpdate(prevProps, prevState, snapshot);
	}

	render() {
		return this._onRender();
	}

	/**
	 * Gets called after the Component has mounted.
	 * @protected
	 */
	_afterMount() {
	}

	/**
	 * Gets called after the Component has updated.
	 * @param previousProperties The properties before the update.
	 * @param previousState The state before the update.
	 * @param snapshot A snapshot.
	 * @protected
	 */
	_afterUpdate(previousProperties, previousState, snapshot) {
	}

	/**
	 * Gets called whenever the Component is rendered.
	 * @protected
	 */
	_onRender() {
		return <></>;
	}
}

Component._key = 0;
