import {faJava} from "@fortawesome/free-brands-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {i18next} from "../../../i18next";
import {Skill} from "./Skill";

i18next
	.addResourceBundle("en", "translation", {
		Documentation: {
			Skillsheet: {
				Skills: {
					JavaDevelopment: `Java Development`
				}
			}
		}
	}, true)
	.addResourceBundle("nl", "translation", {
		Documentation: {
			Skillsheet: {
				Skills: {
					JavaDevelopment: `Java Ontwikkeling`
				}
			}
		}
	}, true);

export const JavaDevelopment = new Skill(null, <FontAwesomeIcon icon={faJava}/>);

function update() {
	JavaDevelopment.name = i18next.t("Documentation.Skillsheet.Skills.JavaDevelopment");
}

i18next.on("languageChanged", language => {
	update();
});

update();
