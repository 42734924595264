import {Component} from "../../Layout/Component";
import {Content} from "../../Layout/Content";
import {ParseTranslate} from "../../Layout/ParseTranslate";
import {Section} from "../../Layout/Section";
import {Quote} from "../../Multimedia/Quote";
import {CurriculumVitaeDocument} from "../CurriculumVitae/CurriculumVitaeDocument";
import {Article} from "./Article";

/**
 * My personal curriculum vitae.
 */
export const CurriculumVitae = Component.Translate(class CurriculumVitae extends Component {
	_afterMount() {
		document.title = this.translate("Documents.Articles.CurriculumVitae.Title");
	}

	_onRender() {
		return (
			<Article>
				<Content>
					<Section title={this.translate("Documents.Articles.CurriculumVitae.Title")}>
						<ParseTranslate translationKey="Documents.Articles.CurriculumVitae.Description">
							<p/>
							<p>
								<i/>
							</p>
						</ParseTranslate>
					</Section>
					<Quote author="Stephen Hawking" date="" source="https://www.goodreads.com/quotes/490245-remember-to-look-up-at-the-stars-and-not-down">
						{this.translate("Documents.Articles.CurriculumVitae.Quote")}
					</Quote>
					<CurriculumVitaeDocument curriculumVitae={this.properties.curriculumVitae}/>
				</Content>
			</Article>
		);
	}
}, [
	{
		language: "en",
		translations: {
			Documents: {
				Articles: {
					CurriculumVitae: {
						Title: `Curriculum Vitae`,
						Description: `
							<0>
								Below you will find my Curriculum Vitae, the document that outlines my skills, passions and professional experience.
								It is difficult to summarize a person, let alone yourself, into a neatly formatted document, but I hope that my attempt at doing so is enough to provide a good impression of who I am, what I do and what I want to do.
							</0>
							<1>
								A CV should be a summarization of my professional experience and skills, and thus I decided to tackle the problem of creating one in a way that is relevant to those skills.
								Hence, the CV you see before you on this page can be read as a traditional document, but also experienced as a multimedia minigame <0>(tip: scroll up)</0>.
							</1>
						`,
						Quote: `
							Remember to look up at the stars and not down at your feet.
							Try to make sense of what you see and wonder about what makes the universe exist.
							Be curious.
							And however difficult life may seem, there is always something you can do and succeed at.
							It matters that you don't just give up.
						`
					}
				}
			}
		}
	}, {
		language: "nl",
		translations: {
			Documents: {
				Articles: {
					CurriculumVitae: {
						Title: `Curriculum Vitae`,
						Description: `
							<0>
								Hieronder vindt u mijn Curriculum Vitae, het document dat mijn vaardigheden, passies en professionele ervaring samenvat.
								Het is moeilijk om een persoon, en zeker jezelf, in een netjes opgesteld document samen te vatten, maar ik hoop dat ik met deze poging een goede indruk geef van wie ik ben, wat ik doe en wat ik graag wil doen.
							</0>
							<1>
								Een CV zou een samenvatting van mijn professionele ervaring en vaardigheden moeten zijn, en dus heb ik besloten om het schrijven van een CV aan te pakken op een manier die ook daadwerkelijk relevant is aan mijn vaardigheden.
								Aldus is de CV die u voor u ziet niet alleen te lezen als document, maar ook als multimedia minigame <0>(tip: scroll omhoog)</0>.
							</1>
						`,
						Quote: `
							Vergeet niet naar de sterren te kijken en niet naar je voeten.
							Probeer te begrijpen wat je ziet en vraag je af waarom het universum bestaat.
							Wees nieuwsgierig.
							En hoe moeilijk het leven ook lijkt, er is altijd wel iets dat je kunt doen en waarin je kunt slagen.
							Het is belangrijk dat je niet zomaar opgeeft.
						`
					}
				}
			}
		}
	}
]);
