/**
 * A professional place.
 */
export class Place {
	/**
	 * Creates a new Place.
	 * @param {string} name The name.
	 * @param {string} location The location.
	 */
	constructor(name, location) {
		/**
		 * The name.
		 * @type {string}
		 */
		this.name = name;

		/**
		 * The location.
		 * @type {string}
		 */
		this.location = location;
	}
}
