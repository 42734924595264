import {i18next} from "../../../../i18next";
import {CSS3Development} from "../../../Skillsheet/Skills/CSS3Development";
import {English} from "../../../Skillsheet/Skills/English";
import {GitDevelopment} from "../../../Skillsheet/Skills/GitDevelopment";
import {GitLabCIDevelopment} from "../../../Skillsheet/Skills/GitLabCIDevelopment";
import {HTML5Development} from "../../../Skillsheet/Skills/HTML5Development";
import {JavaScriptDevelopment} from "../../../Skillsheet/Skills/JavaScriptDevelopment";
import {JSDocDocumentation} from "../../../Skillsheet/Skills/JSDocDocumentation";
import {NodeJSDevelopment} from "../../../Skillsheet/Skills/NodeJSDevelopment";
import {ReactJSDevelopment} from "../../../Skillsheet/Skills/ReactJSDevelopment";
import {Project} from "../Project";
import PlanetsOrbit from "./PlanetsOrbit.png";

i18next
	.addResourceBundle("en", "translation", {
		Documents: {
			Articles: {
				Projects: {
					Spaceshow: {
						Description: `
							The Spaceshow package is a React components package that provides tools to create a space-themed presentation.
							An example can be found on this website, on the Curriculum Vitae page.
						`,
						RoleDescription: `I designed and wrote the package.`
					}
				}
			}
		}
	}, true)
	.addResourceBundle("nl", "translation", {
		Documents: {
			Articles: {
				Projects: {
					Spaceshow: {
						Description: `
							Het Spaceshow pakket is een React componenten pakket dat de middelen bevat om een presentatie te maken met een ruimte-thema.
							Een voorbeeld kan op deze website worden gevonden, op de Curriculum Vitae pagina.
						`,
						RoleDescription: `Ik heb het pakket ontworpen en geschreven.`
					}
				}
			}
		}
	}, true);

export const Spaceshow = new Project("Spaceshow", null, null, new URL("https://gitlab.qub1.com/Qub1/Spaceshow"), [
	CSS3Development,
	English,
	GitDevelopment,
	GitLabCIDevelopment,
	HTML5Development,
	JSDocDocumentation,
	JavaScriptDevelopment,
	NodeJSDevelopment,
	ReactJSDevelopment
], [PlanetsOrbit]);

function update() {
	Spaceshow.description = i18next.t("Documents.Articles.Projects.Spaceshow.Description");
	Spaceshow.roleDescription = i18next.t("Documents.Articles.Projects.Spaceshow.RoleDescription");
}

i18next.on("languageChanged", language => {
	update();
});

update();
