import {i18next} from "../../../i18next";
import {CDevelopment} from "../../Skillsheet/Skills/CDevelopment";
import {CMakeDevelopment} from "../../Skillsheet/Skills/CMakeDevelopment";
import {CUDADevelopment} from "../../Skillsheet/Skills/CUDADevelopment";
import {DebianLinux} from "../../Skillsheet/Skills/DebianLinux";
import {English} from "../../Skillsheet/Skills/English";
import {GitDevelopment} from "../../Skillsheet/Skills/GitDevelopment";
import {MakeDevelopment} from "../../Skillsheet/Skills/MakeDevelopment";
import {Project} from "./Project";

i18next
	.addResourceBundle("en", "translation", {
		Documents: {
			Articles: {
				Projects: {
					EnergyAwareRuntime: {
						Description: `Energy Aware Runtime (EAR) is a framework for monitoring applications and applying energy saving techniques in clusters.`,
						RoleDescription: `I integrated my EnergyManager framework with the existing EAR framework.`
					}
				}
			}
		}
	}, true)
	.addResourceBundle("nl", "translation", {
		Documents: {
			Articles: {
				Projects: {
					EnergyAwareRuntime: {
						Description: `Energy Aware Runtime (EAR) is een framework voor het monitoren van applicaties en het toepassen van energiebesparende technieken in clusters.`,
						RoleDescription: `Ik heb mijn EnergyManager framework geïntegreerd met het bestaande EAR framework.`
					}
				}
			}
		}
	}, true);

export const EnergyAwareRuntime = new Project("Energy Aware Runtime", null, null, new URL("https://www.bsc.es/research-and-development/software-and-apps/software-list/ear-energy-management-framework-hpc"), [
	CDevelopment,
	CMakeDevelopment,
	CUDADevelopment,
	DebianLinux,
	English,
	GitDevelopment,
	MakeDevelopment
]);

function update() {
	EnergyAwareRuntime.description = i18next.t("Documents.Articles.Projects.EnergyAwareRuntime.Description");
	EnergyAwareRuntime.roleDescription = i18next.t("Documents.Articles.Projects.EnergyAwareRuntime.RoleDescription");
}

i18next.on("languageChanged", language => {
	update();
});

update();
