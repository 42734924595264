import {Vector} from "@qub1/qulibjs";
import {Universe} from "@qub1/venginejs";
import {Planet} from "./Planet";
import {SpaceSkybox} from "./SpaceSkybox/SpaceSkybox";
import {Star} from "./Star";

/**
 * A space Universe.
 */
export class Space extends Universe {
	/**
	 * Creates a new Space.
	 * @param {Camera} camera The Camera to use.
	 */
	constructor(camera) {
		super();

		/**
		 * The Camera to use.
		 * @type {Camera}
		 */
		this.camera = camera;
		this.addChild(camera);

		/**
		 * The Skybox to use.
		 * @type {SpaceSkybox}
		 */
		this.skybox = new SpaceSkybox(this.camera);
		this.addChild(this.skybox);

		// Add Rocket

		// The sun
		const sun = new Star();
		sun.color = 0xfff00;
		sun.radius = 10;
		this.addChild(sun);

		// Add Earth
		const earth = new Planet();
		// earth.radius = 6371e3;
		earth.radius = 10;
		earth.color = 0x0000ff;
		earth.coordinates = new Vector(sun.radius + earth.radius);
		sun.addChild(earth);

		// // Get the view frustum
		// let frustum = new THREE.Frustum();
		// frustum.setFromProjectionMatrix(new THREE.Matrix4().multiplyMatrices(this.camera._component.projectionMatrix, this.camera._component.matrixWorldInverse));
		//
		// // Move the star if it is out of frustum
		// while (!frustum.containsPoint(new THREE.Vector3(star.coordinates.x, star.coordinates.y, star.coordinates.z))) {
		// }

		// // Create the stars
		// const sunDiameter = 0.00929826069;
		// const sunRadius = sunDiameter / 2;
		// const scutiRadius = 1.7e3 * sunRadius;
		// for (let index = 0; index < 1000; ++index) {
		// 	let star = new Star();
		// 	star.radius = random.float(sunRadius, scutiRadius);
		// 	star.coordinates = new Vector(random.int(-this.skybox.width / 2, this.skybox.width / 2), random.int(-this.skybox.height / 2, this.skybox.height / 2), random.int(-this.skybox.depth / 2, this.skybox.depth / 2));
		// 	star.rotationVelocity = new Vector(random.float(-1, 1), random.float(-1, 1), random.float(-1, 1));
		// 	star.velocity = new Vector(random.float(-10, 10), random.float(-10, 10), random.float(-10, 10));
		// 	star.color = randomColor({luminosity: "bright", format: "hex"});
		// 	this.addChild(star);
		// }
	}
}
