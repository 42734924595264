import {FullName, Gender, Human, Sex} from "@qub1/qulibjs";

/**
 * Represents me.
 */
export const Quincy = new Human(
	new FullName("Dhr.", "Quincy", "", "Bakker", "Quins", ""),
	Sex.Sexes.find(sex => sex.name === "Male"),
	Gender.Genders.find(gender => gender.name === "Cisgender"),
	new Date("23-07-1995")
);