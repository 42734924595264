import {i18next} from "../../../i18next";
import {BoostDevelopment} from "../../Skillsheet/Skills/BoostDevelopment";
import {CPlusPlusDevelopment} from "../../Skillsheet/Skills/CPlusPlusDevelopment";
import {DoxygenDocumentation} from "../../Skillsheet/Skills/DoxygenDocumentation";
import {English} from "../../Skillsheet/Skills/English";
import {GitDevelopment} from "../../Skillsheet/Skills/GitDevelopment";
import {GitLabCIDevelopment} from "../../Skillsheet/Skills/GitLabCIDevelopment";
import {Project} from "./Project";

i18next
	.addResourceBundle("en", "translation", {
		Documents: {
			Articles: {
				Projects: {
					QuLib: {
						Description: `QuLib is a general-purpose modern C++ library that contains a lot of essential programming tools for working with data.`,
						RoleDescription: `I designed and wrote the library.`
					}
				}
			}
		}
	}, true)
	.addResourceBundle("nl", "translation", {
		Documents: {
			Articles: {
				Projects: {
					QuLib: {
						Description: `QuLib is een moderne C++ code library die een boel essentiële programmeermiddelen bevat voor het werken met data.`,
						RoleDescription: `Ik heb de library ontworpen en geschreven.`
					}
				}
			}
		}
	}, true);

export const QuLib = new Project("QuLib (W.I.P.)", null, null, new URL("https://gitlab.qub1.com/Qub1/QuLib"), [
	BoostDevelopment,
	CPlusPlusDevelopment,
	DoxygenDocumentation,
	English,
	GitDevelopment,
	GitLabCIDevelopment
]);

function update() {
	QuLib.description = i18next.t("Documents.Articles.Projects.QuLib.Description");
	QuLib.roleDescription = i18next.t("Documents.Articles.Projects.QuLib.RoleDescription");
}

i18next.on("languageChanged", language => {
	update();
});

update();
