import {Light, Sphere} from "@qub1/venginejs";

/**
 * A star.
 */
export class Star extends Sphere {
	/**
	 * Creates a new Star.
	 */
	constructor() {
		super();

		/**
		 * The light that provides the Star's lighting.
		 * @type {Light}
		 * @private
		 */
		this._light = new Light();
		this._light.intensity = 1;
		this._light.decay = 1;
		this._light.color = super.color;
		this.addChild(this._light);
	}

	set color(color) {
		// Synchronize the color
		this._light.color = color;
		super.color = color;
	}
}
