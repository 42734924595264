/**
 * Represents a degree obtained from an educational Institution.
 */
export class Degree {
	/**
	 * Creates a new Degree.
	 * @param {string} name The name.
	 * @param {Institution[]} institutions The institutions.
	 * @param {Date} startDate The date the degree was started.
	 * @param {Date} endDate The date the degree was finished/obtained.
	 * @param {string} minor The minor.
	 * @param {string[]} subjects The subjects.
	 * @param {Project[]} projects The related Projects.
	 */
	constructor(name, institutions, startDate, endDate, minor, subjects = [], projects = []) {
		/**
		 * The name.
		 * @type {string}
		 */
		this.name = name;

		/**
		 * The institutions.
		 * @type {Institution[]}
		 */
		this.institutions = institutions;

		/**
		 * The date the degree was started.
		 * @type {Date}
		 */
		this.startDate = startDate;

		/**
		 * The date the degree was finished/obtained.
		 * @type {Date}
		 */
		this.endDate = endDate;

		/**
		 * The minor.
		 * @type {string}
		 */
		this.minor = minor;

		/**
		 * The subjects.
		 * @type {string[]}
		 */
		this.subjects = subjects;

		/**
		 * The related Projects.
		 * @type {Project[]}
		 */
		this.projects = projects;
	}
}
