/**
 * A project.
 */
export class Project {
	/**
	 * Creates a new Project.
	 * @param {string} name The name.
	 * @param {string} description The description.
	 * @param {string} roleDescription A description of your role in the Project.
	 * @param {URL} homepage The homepage.
	 * @param {Skill[]} skills The involved Skills.
	 * @param {Image[]} multimedia Some multimedia demonstrating the Project.
	 */
	constructor(name, description, roleDescription, homepage, skills = [], multimedia = []) {
		/**
		 * The name.
		 * @type {string}
		 */
		this.name = name;

		/**
		 * The description.
		 * @type {string}
		 */
		this.description = description;

		/**
		 * A description of your role in the Project.
		 * @type {string}
		 */
		this.roleDescription = roleDescription;

		/**
		 * The homepage.
		 * @type {URL}
		 */
		this.homepage = homepage;

		/**
		 * The involved Skills.
		 * @type {Skill[]}
		 */
		this.skills = skills;

		/**
		 * Some multimedia demonstrating the Project.
		 * @type {*[]}
		 */
		this.multimedia = multimedia;
	}
}
