import {i18next} from "../../../../i18next";
import {ArduinoDevelopment} from "../../../Skillsheet/Skills/ArduinoDevelopment";
import {CPlusPlusDevelopment} from "../../../Skillsheet/Skills/CPlusPlusDevelopment";
import {English} from "../../../Skillsheet/Skills/English";
import {GitDevelopment} from "../../../Skillsheet/Skills/GitDevelopment";
import {OpenCVDevelopment} from "../../../Skillsheet/Skills/OpenCVDevelopment";
import {VisualStudio} from "../../../Skillsheet/Skills/VisualStudio";
import {Project} from "../Project";
import ComputerVisionDemo from "./Computer-Vision-Demo.mp4";
import Cover from "./Cover.png";
import LongDemo from "./Long-Demo.mp4";

i18next
	.addResourceBundle("en", "translation", {
		Documents: {
			Articles: {
				Projects: {
					Stickuino: {
						Description: `
							Stickuino is a smart toilet freshener that responds to sensor input and hand gestures via webcam input.
							It uses computer vision to process sensor input and determine the best course of action.
						`,
						RoleDescription: `I designed and wrote the code as well as assembled the Arduino-inspired board.`
					}
				}
			}
		}
	}, true)
	.addResourceBundle("nl", "translation", {
		Documents: {
			Articles: {
				Projects: {
					Stickuino: {
						Description: `
							Stickuino is een slimme toilet verfrisser die reageert op sensor input en handgebaren via webcam input.
							Het maakt gebruik van computer visie om sensor input the verwerken en de beste handeling te bepalen.
						`,
						RoleDescription: `Ik heb de code ontworpen en geschreven en ook het Arduino-geïnspireerde bord in elkaar gezet.`
					}
				}
			}
		}
	}, true);

export const Stickuino = new Project("Stickuino", null, null, new URL("https://gitlab.qub1.com/Education/University/Universiteit-Utrecht/Interactie-technologie/computervision"), [
	ArduinoDevelopment,
	CPlusPlusDevelopment,
	English,
	GitDevelopment,
	OpenCVDevelopment,
	VisualStudio
], [
	Cover,
	LongDemo,
	ComputerVisionDemo
]);

function update() {
	Stickuino.description = i18next.t("Documents.Articles.Projects.Stickuino.Description");
	Stickuino.roleDescription = i18next.t("Documents.Articles.Projects.Stickuino.RoleDescription");
}

i18next.on("languageChanged", language => {
	update();
});

update();
