import "@fontsource/open-sans";
import {Component} from "./Component";

/**
 * The outer wrapper.
 */
const Wrapper = Component.Div`
	bottom: 0;
	font-size: 16px;
	left: 0;
	overflow: auto;
	position: absolute;
	right: 0;
	top: 0;

	a {
		text-decoration: none;
	}
`;

/**
 * Represents a page of content.
 */
export class Page extends Component {
	_onRender() {
		return (
			<Wrapper>
				{this.children}
			</Wrapper>
		);
	}
}
